import React, { useState } from "react";
import { Label, AccordionBody, AccordionHeader, AccordionItem, Card, CardBody, CardText, CardTitle, Col, Collapse, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledAccordion, UncontrolledCollapse, Form, Input, InputGroup, FormFeedback } from "reactstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Dropzone from "react-dropzone";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import pdficon from "../../assets/images/brands/pdf.png";

const CustomerShortcut = () => {  
  
  
  //Floating labels forms
  const floatingformik = useFormik({
    initialValues: {
      name: "",
      email: "",
      select: "",
      check: ""
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required"),
      email: Yup.string().email().matches(/^(?!.*@[^,]*,)/).required("Please Enter Your Email"),
      select: Yup.string().required("This field is required"),
      check: Yup.string().required("This field is required"),
    }),

    onSubmit: (values) => {
      // console.log("value", values.password);
    },
  });
  
    //Horizontal form 
  const horizontalformik = useFormik({
    initialValues: {
      firstname: "",
      email: "",
      password: "",
      check: ""
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("This field is required"),
      email: Yup.string().email().matches(/^(?!.*@[^,]*,)/).required("Please Enter Your Email"),
      password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .matches(RegExp('(.*[a-z].*)'), 'At least lowercase letter')
        .matches(RegExp('(.*[A-Z].*)'), 'At least uppercase letter')
        .matches(RegExp('(.*[0-9].*)'), 'At least one number')
        .required("This field is required"),
      check: Yup.string().required("This field is required"),
    }),

    onSubmit: (values) => {
      // console.log("value", values.password);
    },
  });
  
  const [selectedFiles, setselectedFiles] = useState([]);

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  
  return (
    <React.Fragment>
					
						
						
		
		 <UncontrolledAccordion defaultOpen="1" flush>
			<AccordionItem>
			  <AccordionHeader targetId="1">Attachments</AccordionHeader>
			  <AccordionBody accordionId="1">
				<div>
				                 
					 <Dropzone
				  onDrop={acceptedFiles => {
					handleAcceptedFiles(acceptedFiles)
				  }}
				>
				  {({ getRootProps, getInputProps }) => (
					<div className="dropzone">
					  <div
						className="dz-message needsclick"
						{...getRootProps()}
					  >
						<input {...getInputProps()} />
						<p className="fw-normal mb-0"><i className="text-muted bx bxs-cloud-upload" /> Drop files here or click to upload.</p>
					  </div>
					</div>
				  )}
				</Dropzone>
                    
					<div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      })}
                    </div>
					
				 <div className="p-2">
				  <Row className="align-items-center">
					<Col className="col-auto">
					<input
						className="form-check-input mt-3 me-3"
						type="checkbox"
						value=""
						id="defaultCheck1"
						checked
				    />
					<img
						src={pdficon}
						data-dz-thumbnail=""
						height="80"
						className="avatar-sm rounded"						
					  />
					</Col>
					<Col>
					  <Link
						to="#"
						className="text-muted"
					  >
						Quote_4.pdf
					  </Link>
					  <p className="mb-0">
						<strong>125 KB</strong>
					  </p>
					</Col>
				  </Row>
				</div>						
					
					<div>
						
					</div>

                   
                  
				</div>
			  </AccordionBody>
			</AccordionItem>
			<AccordionItem>
			  <AccordionHeader targetId="2">Quote Attachments</AccordionHeader>
			  <AccordionBody accordionId="2">
				  <div className="text-muted">
					
					<div className="d-flex mb-3">						
						<div className="ms-2 flex-grow-1">
							<h6 className="mb-0 font-size-15">
								<label className="text-body mb-0" htmlFor="quotefu">Quote follow-up</label>
							</h6>
							<p className="text-muted mb-0">Follow up on an outstanding quote. <Link>Settings</Link></p>							
						</div>	
						<div className="form-check form-switch form-switch-lg">
							<input
                            type="checkbox"
                            className="form-check-input"
                            id="quotefu"
                            defaultChecked
                            />
						</div>						
					</div>
					
					<div className="d-flex mb-3">						
						<div className="ms-2 flex-grow-1">
							<h6 className="mb-0 font-size-15">
								<label className="text-body mb-0" htmlFor="sitevisitfu">Assessment and visit reminders</label>
							</h6>
							<p className="text-muted mb-0">Remind your client of an upcoming assessment or visit. <Link>Settings</Link></p>							
						</div>	
						<div className="form-check form-switch form-switch-lg">
							<input
                            type="checkbox"
                            className="form-check-input"
                            id="sitevisitfu"
                            defaultChecked
                            />
						</div>						
					</div>
					
					<div className="d-flex mb-3">						
						<div className="ms-2 flex-grow-1">
							<h6 className="mb-0 font-size-15">
								<label className="text-body mb-0" htmlFor="jobfu">Job follow-up</label>
							</h6>
							<p className="text-muted mb-0">Follow up when you close a job. <Link>Settings</Link></p>							
						</div>	
						<div className="form-check form-switch form-switch-lg">
							<input
                            type="checkbox"
                            className="form-check-input"
                            id="jobfu"
                            defaultChecked
                            />
						</div>						
					</div>
					
					<div className="d-flex">						
						<div className="ms-2 flex-grow-1">
							<h6 className="mb-0 font-size-15">
								<label className="text-body mb-0" htmlFor="invoicefu">Invoice follow-up</label>
							</h6>
							<p className="text-muted mb-0">Follow up on an overdue invoice. <Link>Settings</Link></p>							
						</div>	
						<div className="form-check form-switch form-switch-lg">
							<input
                            type="checkbox"
                            className="form-check-input"
                            id="invoicefu"
                            defaultChecked
                            />
						</div>						
					</div>
					
				  </div>				
			  </AccordionBody>
			</AccordionItem>
			<AccordionItem>
			  <AccordionHeader targetId="3">Customer Attachments</AccordionHeader>
			  <AccordionBody accordionId="3">
				<div className="text-muted">
				  
				  <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Custom Field
                      </Label>
                      <Col sm={9}>
                        <Input
                          name="firstname"
                          type="text"
                          className="form-control"
                          id="horizontal-firstname-Input"
                          placeholder="Enter Your"
                          value={horizontalformik.values.firstname}
                          onChange={horizontalformik.handleChange}
                          onBlur={horizontalformik.handleBlur}
                          invalid={
                            horizontalformik.touched.firstname && horizontalformik.errors.firstname ? true : false
                          }
                        />
                        {
                          horizontalformik.errors.firstname && horizontalformik.touched.firstname ? (
                            <FormFeedback type="invalid">{horizontalformik.errors.firstname}</FormFeedback>
                          ) : null
                        }
                      </Col>
                    </Row>
				  
				</div>
			  </AccordionBody>
			</AccordionItem>
		  </UncontrolledAccordion>
						
                      
                 
	 
    </React.Fragment>
  )
}

export default CustomerShortcut;
