import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Card, CardBody, CardTitle, Label, Form, Input, InputGroup, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, Table, Badge, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown, UncontrolledDropdown} from "reactstrap";
import { Rating } from "react-simple-star-rating";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import NotesAttachments from "../../Shortcut/notes-attachments";
import EmailBody from "../../Shortcut/email-body";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { Editor } from '@tinymce/tinymce-react';

const QuoteDetails = () => {

  document.title="Quote for Vipin - SYANPRO";
  const [singlebtn, setSinglebtn] = useState(false)	

  const [modal_standard, setmodal_standard] = useState(false);
  const [editQuoteNo, setShow] = useState(false);
  
  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}         
		  
		  <Row>
			<Col lg="6">
			<Breadcrumbs title="Service" breadcrumbItem="Job for Vipin" />
			</Col>
			<Col lg="6">
			<div className="neg-margin">
				<UncontrolledDropdown className="text-end float-end">
				  <DropdownToggle color="primary" outline type="button">
				  <i className="bx bx-dots-horizontal-rounded font-size-16 me-2"></i> 
					More Actions <i className="mdi mdi-chevron-down"></i>
				  </DropdownToggle>
				  <DropdownMenu>
					<div className="dropdown-header noti-title">
					  <h5 className="font-size-13 text-muted text-truncate mn-0">Send And...</h5>
					</div>
					<DropdownItem href="#"><i className="bx bx-wrench font-size-16 me-2"></i> Convert to Job</DropdownItem>
					<DropdownItem href="#"><i className="bx bx-collection font-size-16 me-2"></i> Convert to Project</DropdownItem>
					<DropdownItem href="#"><i className="bx bx-copy-alt font-size-16 me-2"></i> Duplicate</DropdownItem>
					<DropdownItem href="#"><i className="bx bxs-file-pdf font-size-16 me-2"></i> Download PDF</DropdownItem>
					<div className="dropdown-divider"></div>
					<DropdownItem href="#"><i className="bx bx-hourglass font-size-16 me-2"></i> Mark as Awaiting Response</DropdownItem>
					<DropdownItem href="#"><i className="bx bx-like font-size-16 me-2"></i> Accepted</DropdownItem>
					<DropdownItem href="#"><i className="bx bx-dislike font-size-16 me-2"></i> Declined</DropdownItem>
					<div className="dropdown-divider"></div>
					<DropdownItem href="#"><i className="bx bx-show-alt font-size-16 me-2"></i> Preview as Customer</DropdownItem>
					<DropdownItem href="#"><i className="bx bxs-pencil font-size-16 me-2"></i> Collect Signature</DropdownItem>
				  </DropdownMenu>
				</UncontrolledDropdown>
				
				<Button color="success" outline className="btn float-end me-3"><i className="bx bx-edit-alt font-size-16 me-2"></i> Edit</Button>	
				
				<Button color="success" className="btn float-end me-3"
				onClick={(e) => {
				e.preventDefault();
				tog_standard();
				}}
				>
				<i className="bx bx-mail-send font-size-16 me-2"></i> Send Email
				</Button>
				
			</div>
			</Col>
		  </Row>
		  
		  <Modal
			  size="lg"
			  isOpen={modal_standard}
			  toggle={() => {
				tog_standard();
			  }}
			  centered
			>
			  <div>                       
				<button
				  type="button"
				  onClick={() => {
					setmodal_standard(false);
				  }}
				  className="close"
				  data-dismiss="modal"
				  aria-label="Close"
				>
				  <span aria-hidden="true">&times;</span>
				</button>
			  </div>
			  <div className="modal-body">
			   
			   <EmailBody />
			   
			  </div>
			   <div className="modal-footer">
			   <Button color="primary" outline className="btn float-end me-1"
			   onClick={() => {
					tog_standard();
				  }}
			   >
			   Cancel
			   </Button>	
			   <Button color="success" className="btn float-end me-3">Send Email</Button>
				
			  </div>
			</Modal>
		  		  
		    <Row>
            <Col lg="12">
             <Card>
                <CardBody className="card-body-a">
				
				<Form className="mb-4">   
					<div className="row">
					 <div className="col-sm-8">
						<Row className="mb-4">
							<h4>Quote for Vipin</h4>
						</Row>
						
					
						
						<Row className="mb-4">
						  <Label htmlFor="formrow-InputCity">Job Title</Label>
						  <Col sm={6}>
													
						  </Col>
						  <Col sm={6}>
													
						  </Col>
						</Row>	

						<Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputCity">Quote Expiry</Label>
                                        
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputState">Salesman</Label>
                                                
                        </div>
                      </Col>

                      <Col lg={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputZip">Reference</Label>
                                              
                        </div>
                      </Col>
                    </Row>
						
					 </div>
					 <div className="col-sm-4">
						<Row><p className="fw-bold">Job Details</p></Row>
						<Row className="mb-4">
							<Col lg={6} ><div id="QuoteNo" className="mt-1">Quote No. #01</div></Col>
							<Col lg={6}>
							
							</Col>						 
						</Row>
						<Row className="mb-4">
						<Col lg={6}>
							Rate Oppertunity
						</Col>
						<Col lg={6}>
							<Rating
                          size={20}
						  initialValue={3}
                          fillColorArray={[
                            '#f14f45',
                            '#f16c45',
                            '#f18845',
                            '#f1b345',
                            '#f1d045'
                          ]}
                          transition
                        />
						</Col>
                                                
                      </Row>
					 </div>
					</div>
                    
					   
                  </Form>
				  
				  <span className="placeholder col-12 placeholder-xs bg-light mb-4"></span>			  
				  
				  
				  Product grid
					
					
					<Row className="mt-4">
						<Col lg={7}>
						Notes to customer
						</Col>
						<Col lg={5}>						
						Totals						
						</Col>
					</Row>
					
					<span className="placeholder col-12 placeholder-xs bg-light mt-4 mb-4"></span>	
					
					<Row>
					<Col>
						<NotesAttachments />
					</Col>
					</Row>
					
					
					
				</CardBody>
			 </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default QuoteDetails;
