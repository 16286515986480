import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown, } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from '@fullcalendar/list';
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import allLocales from '@fullcalendar/core/locales-all';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import {
  addNewEvent as onAddNewEvent,
  deleteEvent as onDeleteEvent,
  getCategories as onGetCategories,
  getEvents as onGetEvents,
  updateEvent as onUpdateEvent,
} from "../../store/actions";

import DeleteModal from "./DeleteModal";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

const Calender = props => {

  //meta title
  document.title = "Calendar | Skote - React Admin & Dashboard Template";

  const dispatch = useDispatch();
  const [event, setEvent] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [singlebtn, setSinglebtn] = useState(false)	

  // category validation
  const categoryValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (event && event.title) || '',
      category: (event && event.category) || '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Event Name"),
      category: Yup.string().required("Please Enter Your Billing Name"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateEvent = {
          id: event.id,
          title: values.title,
          classNames: values.category + " text-white",
          start: event.start,
        };
        // update event
        dispatch(onUpdateEvent(updateEvent));
        categoryValidation.resetForm();
      } else {
        const newEvent = {
          id: Math.floor(Math.random() * 100),
          title: values["title"],
          start: selectedDay ? selectedDay.date : new Date(),
          className: values['category']
            ? values['category'] + " text-white"
            : "bg-primary text-white"
          ,
        };
        // save new event
        dispatch(onAddNewEvent(newEvent));
        categoryValidation.resetForm()
      }
      toggle();
    },
  });


  const CalendarProperties = createSelector(
    (state) => state.calendar,
    (Calendar) => ({
      events: Calendar.events,
      categories: Calendar.categories,
    })
  );

  const { events, categories } = useSelector(CalendarProperties);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [modalCategory, setModalCategory] = useState(false);
  const [selectedDay, setSelectedDay] = useState(0);

  useEffect(() => {
    dispatch(onGetCategories());
    dispatch(onGetEvents());
    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    });
  }, [dispatch]);

  useEffect(() => {
    if (!modalCategory && !isEmpty(event) && !!isEdit) {
      setTimeout(() => {
        setEvent({});
        setIsEdit(false);
      }, 500);
    }
  }, [modalCategory, event, isEdit]);

  /**
   * Handling the modal state
   */
  const toggle = () => {
    if (modalCategory) {
      setModalCategory(false);
      setEvent(null);
      setIsEdit(false);
    } else {
      setModalCategory(true);
    }
  };

  /**
   * Handling date click on calendar
   */
  const handleDateClick = arg => {
    const date = arg["date"];
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const currectDate = new Date();
    const currentHour = currectDate.getHours();
    const currentMin = currectDate.getMinutes();
    const currentSec = currectDate.getSeconds();
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    );
    const modifiedData = { ...arg, date: modifiedDate };

    setSelectedDay(modifiedData);
    toggle();
  };

  /**
   * Handling click on event on calendar
   */
  const handleEventClick = arg => {
    const event = arg.event;
    setEvent({
      id: event.id,
      title: event.title,
      // title_category: event.title_category,
      start: event.start,
      className: event.classNames,
      category: event.classNames[0],
      event_category: event.classNames[0],
    });
    setDeleteId(event.id)
    setIsEdit(true);
    setModalCategory(true)
    toggle();
  };

  /**
    * On delete event
  */
  const handleDeleteEvent = () => {
    if (deleteId) {
      dispatch(onDeleteEvent(deleteId));
    }
    setDeleteModal(false);
  };

  /**
   * On category darg event
   */
  const onDrag = event => {
    event.preventDefault();
  };

  /**
   * On calendar drop event
   */
  const onDrop = event => {
    const date = event['date'];
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const currectDate = new Date();
    const currentHour = currectDate.getHours();
    const currentMin = currectDate.getMinutes();
    const currentSec = currectDate.getSeconds();
    const modifiedDate = new Date(year, month, day, currentHour, currentMin, currentSec);

    const draggedEl = event.draggedEl;
    const draggedElclass = draggedEl.className;
    if (draggedEl.classList.contains('external-event') && draggedElclass.indexOf("fc-event-draggable") == -1) {
      const modifiedData = {
        id: Math.floor(Math.random() * 100),
        title: draggedEl.innerText,
        start: modifiedDate,
        className: draggedEl.className,
      };
      dispatch(onAddNewEvent(modifiedData));
    }
  };

  //set the local language
  const enLocal = {
    "code": "en-nz",
    "week": {
      "dow": 1,
      "doy": 4
    },
    "buttonHints": {
      "prev": "Previous $0",
      "next": "Next $0",
      "today": "This $0"
    },
    "viewHint": "$0 view",
    "navLinkHint": "Go to $0"
  };
  const [isLocal, setIsLocal] = useState(enLocal);
  const handleChangeLocals = (value) => {
    setIsLocal(value);
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
		  
		   <Row>
			<Col lg="6">
			<Breadcrumbs title="Schedule" breadcrumbItem="Schedule" />
			</Col>
			<Col lg="6" className="neg-margin">
				<div className="d-flex gap-2 float-end">
				<Button color="primary" outline className="btn me-2"><i className="bx bx-filter-alt font-size-16 align-middle me-2"></i> Filter</Button>
				<Link to="/map"><Button color="primary" className="btn me-2"><i className="bx bx-map font-size-16 align-middle me-2"></i> Map</Button></Link>
				<Link to="/map"><Button color="secondary" className="btn me-2"><i className="bx bx-detail font-size-16 align-middle me-2"></i> List</Button></Link>
				<Button
				  color="success"
				   className="btn me-2"
				  onClick={toggle}
				>
				  <i className="bx bx-plus font-size-16 align-middle me-2" />
				  New Event
				</Button>
				
				<Dropdown
				 isOpen={singlebtn}
				 toggle={() => setSinglebtn(!singlebtn)}
				 className="mt-4 mt-sm-0"
				>
				<DropdownToggle className="btn btn-primary" caret>
				  <i className="bx bx-dots-horizontal-rounded font-size-16 align-middle me-2"></i>
				  More Actions <i className="mdi mdi-chevron-down" />
				</DropdownToggle>
				<DropdownMenu>				  
				  {categories &&
				  (categories || [])?.map((category) => (
					<DropdownItem
					  key={"cat-" + category.id}					  
					  onDrag={event => onDrag(event, category)}
					>					  
					  {category.title}
					</DropdownItem>
				  ))}				  
				</DropdownMenu>
				</Dropdown>	
				
				</div>
			</Col>
		  </Row>
		  
          
		  
		  
          
              
			<Card className="mainCard">
			<CardBody>
				<Row> 
                <Col lg={3} id="external-events">
					<div>
						{/* show below text if there is nothing as unschedule */}
						<h6><i className="bx bxs-left-arrow font-size-12 align-middle me-2 pb-1"></i> Unscheduled</h6>
						<p className="text-muted"> 
						Drag items here to unschedule them
						</p>                        
					</div>
				</Col>
				<Col lg={9}>
					<div>
                      {/* fullcalendar control */}
                      <FullCalendar
                        plugins={[
                          BootstrapTheme,
                          dayGridPlugin,
                          listPlugin,
                          interactionPlugin,
                        ]}
                        slotDuration={"00:15:00"}
                        handleWindowResize={true}
                        themeSystem="bootstrap"
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                        }}
                        locale={isLocal}
                        events={events}
                        editable={true}
                        droppable={true}
                        selectable={true}
                        dateClick={handleDateClick}
                        eventClick={handleEventClick}
                        drop={onDrop}
                      />
					  </div>
					  </Col>
					</Row>
				</CardBody>
				</Card>

                  <Modal
				    size="lg"
                    isOpen={modalCategory}
                    className={props.className}
                    centered
                  >
                    <ModalHeader toggle={toggle} tag="h5">
                      {!!isEdit ? "Edit Event" : "Add Event"}
                    </ModalHeader>
                    <ModalBody className="p-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          categoryValidation.handleSubmit();
                          return false;
                        }}
                      >
					  
					 <Row className="mb-1">
						  <Label htmlFor="formrow-InputCity">Event Name</Label>
						  <Col sm={6}>
							<Input
							  name="firstname"
							  type="text"
							  className="form-control"
							  id="horizontal-firstname-Input"
							  placeholder="Title"
							/>								
						  </Col>
						  <Col sm={6}>
							<Input
							  name="firstname"
							  type="text"
							  className="form-control text-end ar-type"
							  id="horizontal-firstname-Input"
							  placeholder="ظيفي"
							/>								
						  </Col>
						</Row>	
						
						<Row className="mb-3">
						<Col>
						<textarea
						  id="message"
						  rows="4"
						  className="form-control rounded-bottom"
						  placeholder="Details"
						></textarea>
						</Col>
					</Row>
					
					<Row className="mb-3">
						<Col xs={6}>
							<Row>
								<Col lg={6} className="me-0 pe-0">
									<Label>Start date</Label>
									<Input type="text" className="group-input-a" value="March 15, 2024" />
								  </Col>
								  <Col lg={6} className="ms-0 ps-0">
									<Label>End date</Label>
									<Input type="text" className="group-input-b" />
								  </Col>
							</Row>
						</Col>
						<Col xs={6}>
							<Row>
								 <Col lg={6} className="me-0 pe-0">
									<Label>Time</Label>
									<Input type="text" className="group-input-a" placeholder="Start time" />
								  </Col>
								  <Col lg={6} className="ms-0 ps-0">
									<label htmlFor="schedulelater">
										<input
										className="form-check-input me-2"
										type="checkbox"
										value=""
										id="schedulelater"
									  />									  
										All day
									</label>									
									<Input type="text" className="group-input-b" placeholder="End time" />
								  </Col>
							</Row>
						</Col>
					</Row>
					  
                        <Row>                          
                          <Col xs={6}>
                            <div className="mb-3">
                              <Label>Repeat</Label>
                              <Input
                                type="select"
                                name="category"
                                placeholder="All Day Event"
                                onChange={categoryValidation.handleChange}
                                onBlur={categoryValidation.handleBlur}
                                value={categoryValidation.values.category || ""}
                                invalid={
                                  categoryValidation.touched.category && categoryValidation.errors.category ? true : false
                                }
                              >
                                <option value="bg-danger">Danger</option>
                                <option value="bg-success">Success</option>
                                <option value="bg-primary">Primary</option>
                                <option value="bg-info">Info</option>
                                <option value="bg-dark">Dark</option>
                                <option value="bg-warning">Warning</option>
                              </Input>
                              {categoryValidation.touched.category && categoryValidation.errors.category ? (
                                <FormFeedback type="invalid">{categoryValidation.errors.category}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>

                        <Row className="mt-2">
                          <Col xs={6}>
                            {isEdit &&
                              <Button type="button" color="btn btn-danger" id="btn-delete-event" onClick={() => { toggle(); setDeleteModal(true) }}>Delete</Button>
                            }
                          </Col>

                          <Col xs={6} className="text-end">
                            <Button color="light"
                              type="button"
                              className="me-1"
                              onClick={toggle}
                            >
                              Close
                            </Button>
                            <Button
                              type="submit"
                              color="success"
                              id="btn-save-event"
                            >
                              Save
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                
            
        </Container>
      </div>
    </React.Fragment>
  );
};

Calender.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default Calender;
