import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Card, CardBody, CardTitle, Label, Form, Input, InputGroup, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, 
Table, Badge, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown, UncontrolledDropdown, UncontrolledTooltip} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from 'yup';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import NotesAttachments from "../../Shortcut/notes-attachments";

const NewAllocation = () => {

  document.title="New Allocation - SYANPRO";
  const [singlebtn, setSinglebtn] = useState(false);
  const [editQuoteNo, setShow] = useState(false);
  const [data_attr, setdata_attr] = useState(56);
  
  //Floating labels forms
  const floatingformik = useFormik({
    initialValues: {
      name: "",
      email: "",
      select: "",
      check: ""
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required"),
      email: Yup.string().email().matches(/^(?!.*@[^,]*,)/).required("Please Enter Your Email"),
      select: Yup.string().required("This field is required"),
      check: Yup.string().required("This field is required"),
    }),

    onSubmit: (values) => {
      // console.log("value", values.password);
    },
  });
  
    //Horizontal form 
  const horizontalformik = useFormik({
    initialValues: {
      firstname: "",
      email: "",
      password: "",
      check: ""
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("This field is required"),
      email: Yup.string().email().matches(/^(?!.*@[^,]*,)/).required("Please Enter Your Email"),
      password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .matches(RegExp('(.*[a-z].*)'), 'At least lowercase letter')
        .matches(RegExp('(.*[A-Z].*)'), 'At least uppercase letter')
        .matches(RegExp('(.*[0-9].*)'), 'At least one number')
        .required("This field is required"),
      check: Yup.string().required("This field is required"),
    }),

    onSubmit: (values) => {
      // console.log("value", values.password);
    },
  });
 

  return (
    <React.Fragment>                      
		  
				 <div className="padding-b">
					<Row className="mb-3">
					<Col><h3 className="text-body">Return Products</h3></Col>
					</Row>
					<Row className="mb-3">										
					<Col sm={6}>
						<Label>Document No. #ALT04</Label>
						<div className="row">
						{ editQuoteNo &&
						<div className="col-sm-7 me-1 ms-0 pe-0">
						<Input
						  name="firstname"
						  type="text"
						  className="form-control"
						  id="horizontal-firstname-Input"
						  placeholder="New Number"
						/>	
						</div>
						}
						<div className="col-sm-4 ms-0">
						<Button color="primary" outline className="btn" onClick={()=>setShow(!editQuoteNo)}>{editQuoteNo === true ? 'Update' : 'Change'}</Button>
						</div>
						</div>
					</Col>
					</Row>							
						
					<Row className="mb-3">
					  <Label htmlFor="formrow-InputCity">Item Name</Label>
					  <Col sm={12}>
						<Input
						  name="firstname"
						  type="text"
						  className="form-control"
						  id="horizontal-firstname-Input"
						  placeholder="Search Item"
						/>								
					  </Col>					  
					</Row>	
						
					<div className="table-responsive mb-4">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
						  <th><input className="form-check-input" type="checkbox" value="" /></th>
                          <th>#</th>
                          <th>Items</th>
                          <th>Unit Cost</th>
                          <th>Quantity Left</th>
						  <th>Quantity to Return</th>
						  <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
						  <th><input className="form-check-input" type="checkbox" value="" id="schedulelater"/></th>
                          <th scope="row">1</th>
                          <td>Air Conditional Copper Cable</td>
                          <td>55.00</td>
                          <td>11.00</td>
						  <td><Input type="text" className="short-input" placeholder="0" /></td>                          
                          <td className="text-end">
							<Link to="#" className="btn btn-sm btn-soft-danger">
								<i className="mdi mdi-delete-outline" />								
							</Link>
						  </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
					
					<Row>
					<Col>
						<NotesAttachments />
					</Col>
					</Row>
					
				</div>	
					
			
    </React.Fragment>
  )
}

export default NewAllocation;
