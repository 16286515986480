import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Card, CardBody, CardTitle, Label, Form, Input, InputGroup, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, 
Table, Badge, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown, Offcanvas, OffcanvasHeader, OffcanvasBody} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from 'yup';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import RequestsListing from "./requests-listing";
import Filter from "../Shortcut/filter";


const Requests = () => {

  const [singlebtn, setSinglebtn] = useState(false)	
  document.title="Jobs - SYANPRO";
  const [isRight, setIsRight] = useState(false);
  const toggleRightCanvas = () => {
	setIsRight(!isRight);
  };
  
  const [modal_standard, setmodal_standard] = useState(false);
  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}         
		  
		  <Row>
			<Col lg="6">
			<Breadcrumbs title="Services" breadcrumbItem="Request" />
			</Col>
			<Col lg="6" className="neg-margin">
				<div className="d-flex gap-2 float-end">
				<Button color="primary" outline className="btn me-2" onClick={toggleRightCanvas}><i className="bx bx-filter-alt font-size-16 align-middle me-2"></i> Filter</Button>				
				
				<Link to="/new-request"><Button color="success" className="btn me-2"><i className="bx bx-plus font-size-16 align-middle me-2"></i> New Request</Button></Link>
				
				<Dropdown
				 isOpen={singlebtn}
				 toggle={() => setSinglebtn(!singlebtn)}
				 className="mt-4 mt-sm-0"
				>
				<DropdownToggle className="btn btn-primary" caret>
				  <i className="bx bx-dots-horizontal-rounded font-size-16 align-middle me-2"></i>
				  More Actions <i className="mdi mdi-chevron-down" />
				</DropdownToggle>
				<DropdownMenu>
				  <DropdownItem>Quotes Report</DropdownItem>
				  <DropdownItem>Quote Settings</DropdownItem>
				  <DropdownItem>Email Template</DropdownItem>
				</DropdownMenu>
				</Dropdown>	
				</div>
			</Col>
		  </Row>	  
		  
		  		  
		   <Row>	
            <Col lg={12}> 			  
              <RequestsListing />
            </Col>			
          </Row>
        </Container>
      </div>
	  
	  
	  <Offcanvas
		isOpen={isRight}
		direction="end"
		toggle={toggleRightCanvas}>
		<OffcanvasHeader toggle={toggleRightCanvas}>
			Filters
		</OffcanvasHeader>
		<OffcanvasBody>
			<Filter />
		</OffcanvasBody>
	  </Offcanvas>
	  
    </React.Fragment>
  )
}

export default Requests;
