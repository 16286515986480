import usFlag from "../assets/images/flags/us.jpg"
import saudi from "../assets/images/flags/saudi.jpg"
// import spain from "../assets/images/flags/spain.jpg"
// import germany from "../assets/images/flags/germany.jpg"
// import italy from "../assets/images/flags/italy.jpg"
// import russia from "../assets/images/flags/russia.jpg"

const languages = {
  // sp: {
    // label: "Spanish",
    // flag: spain,
  // },
  // gr: {
    // label: "German",
    // flag: germany,
  // },
  // it: {
    // label: "Italian",
    // flag: italy,
  // },
  ar: {
    label: "العربية",
    flag: saudi,
  },
  en: {
    label: "English",
    flag: usFlag,
  },
}

export default languages
