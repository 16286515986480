import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Card, CardBody, CardTitle, Label, Form, Input, InputGroup, FormFeedback, Modal, ModalHeader, 
ModalBody, ModalFooter, Table, Badge, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown, UncontrolledDropdown} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ProductServiceGrid from "../Shortcut/product-service-grid";
import GridTotal from "../Shortcut/totals";
import CustomPDF from "../Shortcut/custom-pdf";
import NotesAttachments from "../Shortcut/notes-attachments";
import CustomerSelection from "../Shortcut/customer-selection";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

const NewQuote = () => {

  document.title="New Customer - SYANPRO";
  const [singlebtn, setSinglebtn] = useState(false)	
  const [modal_standard, setmodal_standard] = useState(false);
  const [editQuoteNo, setShow] = useState(false);
  
  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }
    function tog_fullscreen() {
    setmodal_fullscreen(!modal_fullscreen);
    removeBodyCss();
  }

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}         
		  
		  <Row>
			<Col lg="10">
			<Breadcrumbs title="CRM" breadcrumbItem="New Customer" />
			</Col>
			<Col lg="2">
				
			</Col>
		  </Row>
		  		  
		   <Row>
            <Col lg="12">
             <Card className="mainCard">
                <CardBody className="card-body-a">
				
				<Form className="mb-4">   
					<div className="row">
					 <div className="col-sm-8">
						<Row className="mb-4">
							<h3>Invoice for Vipin</h3>
					</Row>
						
					<Modal
                      isOpen={modal_standard}
                      toggle={() => {
                        tog_standard();
                      }}
                    >
                      <div>                       
                        <button
                          type="button"
                          onClick={() => {
                            setmodal_standard(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                       
					   <CustomerSelection />
					   
                      </div>
                    </Modal>
						
						<Row className="mb-4">
						  <Label htmlFor="formrow-InputCity">Invoice Subject</Label>
						  <Col sm={6}>
							<Input
							  name="firstname"
							  type="text"
							  className="form-control"
							  id="horizontal-firstname-Input"
							  placeholder="Subject"
							/>								
						  </Col>
						  <Col sm={6}>
							<Input
							  name="firstname"
							  type="text"
							  className="form-control text-end ar-type"
							  id="horizontal-firstname-Input"
							  placeholder="مسمى وظيفي"
							/>								
						  </Col>
						</Row>	

					<Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputCity">Billing Address</Label>
                                        
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputZip">Service Address</Label>
                           <p className="text-muted">(Same as billing address)     </p>             
                        </div>
                      </Col>
                    </Row>
						
					 </div>
					 <div className="col-sm-4">
						<Row><p className="fw-bold">Invoice Details</p></Row>
						<Row className="mb-4">
							<Col lg={6} ><div id="QuoteNo" className="mt-1">Invoice No. #01</div></Col>
							<Col lg={6}>
							<div className="row">
							{ editQuoteNo &&
							<div className="col-sm-7 me-0 ms-0 ps-0 pe-0">
							<Input
							  name="firstname"
							  type="text"
							  className="form-control-sm"
							  id="horizontal-firstname-Input"
							  placeholder="New Number"
							/>	
							</div>
							}
							<div className="col-sm-4 ms-0">
							<Button color="primary" outline className="btn-sm" onClick={()=>setShow(!editQuoteNo)}>{editQuoteNo === true ? 'Update' : 'Change'}</Button>
							</div>
							</div>
							</Col>						 
						</Row>
					  <Row className="mb-4">
						<Col lg={6}>
							Issue Date
						</Col>
						<Col lg={6}>
							<Link>Date Sent</Link>
						</Col>                                                
                      </Row>
					  <Row className="mb-4">
						<Col lg={6}>
							Payment Due
						</Col>
						<Col lg={6}>
							<Link>Net 30</Link>
						</Col>                                                
                      </Row>
					 </div>
					</div>
                    
					   
                  </Form>
				  
				  <span className="placeholder col-12 placeholder-xs bg-light mb-4"></span>			  
				  
				  
				  <ProductServiceGrid />
					
					
					<Row className="mt-4">
						<Col lg={7}>
						<textarea
						  id="message"
						  rows="5"
						  className="form-control rounded-bottom"
						  placeholder="Customer Message"
						></textarea>
						
						
						<Row className="mt-4">						
						<CustomPDF />
						</Row>
						
						</Col>
						<Col lg={5}>						
						<GridTotal />						
						</Col>
					</Row>
					
					<span className="placeholder col-12 placeholder-xs bg-light mt-4 mb-4"></span>	
					
					<Row>
					<Col>
						<NotesAttachments />
					</Col>
					</Row>
					
					<Row>
					<Col lg={2}>
						<Button color="primary" outline className="btn">Cancel</Button>
					</Col>
					<Col lg={10} className="text-end">		
						
					<UncontrolledDropdown className="text-end float-end">
                      <DropdownToggle color="success" type="button">
                        Save And... <i className="mdi mdi-chevron-down"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        <div className="dropdown-header noti-title">
                          <h5 className="font-size-13 text-muted text-truncate mn-0">Send And...</h5>
                        </div>
                        <DropdownItem href="#"><i className="bx bx-mail-send font-size-16 me-2"></i> Send as Email</DropdownItem>
                        <DropdownItem href="#"><i className="bx bx-wrench font-size-16 me-2"></i> Convert to Job</DropdownItem>
                        <DropdownItem href="#"><i className="bx bxs-file-pdf font-size-16 me-2"></i> Save and Download PDF</DropdownItem>
                        <div className="dropdown-divider"></div>
                        <DropdownItem href="#"><i className="bx bx-hourglass font-size-16 me-2"></i> Mark as Awaiting Response</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
					
						<Link to="/quote-detail"><Button color="success" outline className="btn float-end me-3">Save Quote</Button></Link>						
					</Col>					
					</Row>
					
					
				</CardBody>
			 </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default NewQuote;
