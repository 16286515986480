import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Card, CardBody, CardTitle, Label, Form, Input, InputGroup, FormFeedback, 
Table, Badge, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, 
ButtonDropdown, UncontrolledDropdown, CardText, CardHeader} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import ProductServiceGrid from "../../Shortcut/product-service-grid";
import GridTotal from "../../Shortcut/totals";
import CustomPDF from "../../Shortcut/custom-pdf";
import NotesAttachments from "../../Shortcut/notes-attachments";
import NewJobBodyA from "./new-job-body-a";
import NewJobTopRight from "./new-job-topright";
import NewJobTopLeft from "./new-job-topleft";
import TaskList from "./task-list";

const NewJob = () => {

  document.title="New Quote - SYANPRO";
  const [singlebtn, setSinglebtn] = useState(false)	 

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>       
		  
		  <Row>
			<Col lg="10">
			<Breadcrumbs title="Services" breadcrumbItem="New Job" />
			</Col>
			<Col lg="2">
				
			</Col>
		  </Row>
		  		  
		   <Row>
            <Col lg="12">
             <Card className="mainCard">
                <CardBody className="card-body-a">				
				
				 <div className="row mb-4">
				  <div className="col-sm-8">
					<NewJobTopLeft />
				  </div>
				  <div className="col-sm-4">
				    <div className="ps-5">
					 <NewJobTopRight />											  
					</div>
				  </div>
				</div>  
				  
				<span className="placeholder col-12 placeholder-xs bg-light mb-4"></span>	
				  
				<NewJobBodyA />				  
				  
				<span className="placeholder col-12 placeholder-xs bg-light mb-4"></span>
				
				<TaskList />				  
				  
				<span className="placeholder col-12 placeholder-xs bg-light mb-4"></span>
				
				<div className="mb-4">					  
				  <input
					className="form-check-input"
					type="checkbox"
					value=""
					id="defaultCheck1"					
				  />
				  <label className="form-check-label ms-2 text-primary" htmlFor="defaultCheck1">
					Copy Products / Service details from Quote
				  </label>					  
			    </div>
				
				<ProductServiceGrid />					
					
				<Row className="mt-4">
					<Col lg={7}>
					<textarea
					  id="message"
					  rows="5"
					  className="form-control rounded-bottom"
					  placeholder="Customer Message"
					></textarea>					
					
					<Row className="mt-4">						
					<CustomPDF />
					</Row>
					
					</Col>
					<Col lg={5}>						
					<GridTotal />						
					</Col>
				</Row>
					
				<span className="placeholder col-12 placeholder-xs bg-light mt-4 mb-4"></span>	
					
				<Row>
				<Col>
					<NotesAttachments />
				</Col>
				</Row>
					
				<Row>
				<Col lg={2}>
					<Button color="primary" outline className="btn">Cancel</Button>
				</Col>
				<Col lg={10} className="text-end">		
					
				<UncontrolledDropdown className="text-end float-end">
				  <DropdownToggle color="success" type="button">
					Save And... <i className="mdi mdi-chevron-down"></i>
				  </DropdownToggle>
				  <DropdownMenu>
					<div className="dropdown-header noti-title">
					  <h5 className="font-size-13 text-muted text-truncate mn-0">Send And...</h5>
					</div>
					<DropdownItem href="#"><i className="bx bx-mail-send font-size-16 me-2"></i> Send as Email</DropdownItem>
					<DropdownItem href="#"><i className="bx bx-wrench font-size-16 me-2"></i> Convert to Job</DropdownItem>
					<DropdownItem href="#"><i className="bx bxs-file-pdf font-size-16 me-2"></i> Save and Download PDF</DropdownItem>
					<div className="dropdown-divider"></div>
					<DropdownItem href="#"><i className="bx bx-hourglass font-size-16 me-2"></i> Mark as Awaiting Response</DropdownItem>
				  </DropdownMenu>
				</UncontrolledDropdown>
				
					<Link to="/job-detail"><Button color="success" outline className="btn float-end me-3">Save Job</Button></Link>						
				</Col>					
				</Row>
					
					
				</CardBody>
			 </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default NewJob;
