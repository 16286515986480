import React, { useState } from "react";
import { Card, Col, Container, Row, CardBody, CardTitle, Label, Button, Form, Input, InputGroup, FormFeedback } from "reactstrap";
import { Link } from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const ProductServiceGrid = () => {
	
  const [isActive, setActive] = useState("false");
  const handleToggle = () => {
  setActive(!isActive);  };

  return (
    <React.Fragment>
	<Row className="mb-1">
		<Col lg={6}>
			<p className="fw-bolder">Product / Service</p>
		</Col>
		<Col lg={2}>
			<p className="fw-bolder">Qty.</p>
		</Col>
		<Col lg={2}>
			<p className="fw-bolder">Unit Price</p>
		</Col>
		<Col lg={2}>
			<p className="fw-bolder">Total</p>
		</Col>
	  </Row>
      <Row>
	  <Col lg={1} className="col-lg-1-spl">
	  <Link to="/newquote"><i className="bx bx-move font-size-16"></i></Link>
	  </Col>
		<Col lg={5} className="col-lg-5-spl">			
			<Input
				type="text"
				name="firstname"
				className="form-control border-bottom-0 rounded-top rl-blr"
				id="formrow-firstname-Input"
				placeholder="Name"				
			/>
			<textarea
			  id="message"
			  rows="4"
			  className="form-control rounded-bottom rl-tlr"
			  placeholder="Description"
			></textarea>
		</Col>
		<Col lg={2}>
			<Input
				type="text"
				name="firstname"
				className="form-control"
				id="formrow-firstname-Input"
				value="1"
			/>
			<div className="border mt-2">
			<Link className="align-middle"><i className="bx bx-camera pic-browse-thumb"></i></Link>
			</div>
		</Col>
		<Col lg={2}>
		<div className={isActive ? "cost-field-Container" : "cost-field-Container-a"}>
		  <div className="input-group mb-3">
			<span className="input-group-text" id="option-date">SR</span>
			<Input type="text" name="date" defaultValue="" aria-describedby="option-date" placeholder="0.00" />
		  </div>
		  <div className="cost-container">
			<div>
			 <p className="fw-bold font-size-10 mb-1">COST</p>						  
			 <div className="input-group mb-2">
			 <span className="input-group-text" id="option-date">SR</span>
			 <Input type="text" name="date" defaultValue="" aria-describedby="option-date" placeholder="0.00" />
		     </div>	  
			</div>
			<div>
			 <p className="fw-bold font-size-10 mb-1">MARKUP</p>						  
			 <div className="input-group mb-2">
			 <span className="input-group-text" id="option-date">%</span>
			 <Input type="text" name="date" defaultValue="" aria-describedby="option-date" placeholder="0" />
		     </div>	  
			</div>
		  </div>
		  <Button color="success" outline className="btn btn-sm" onClick={handleToggle}>Add Costs</Button>
		</div>
		</Col>
		<Col lg={2}>
			<div className="input-group mb-3">
			<span className="input-group-text" id="option-date">SR</span>
			<Input type="text" name="date" defaultValue="" aria-describedby="option-date" placeholder="0.00" />
		    </div>
			<Button color="danger" outline className="btn-sm float-end">Delete</Button>
		</Col>
	  </Row>
	  <div className="d-flex gap-2 align-right mt-4">	
		<Button color="success" className="btn"><i className="bx bx-plus font-size-16 align-middle"></i> Add Line Item</Button>
		<Button color="success" outline className="btn me-2"><i className="bx bx-check-square font-size-16 align-middle"></i> Add Optional Line Item</Button>
		<Button color="success" outline className="btn"><i className="bx bx-align-left font-size-16 align-middle"></i> Add Text</Button>
	  </div>
    </React.Fragment>
  )
}

export default ProductServiceGrid;
