import React, { useEffect, useMemo, useState } from "react";
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';

import {
    getJobList as onGetJobList,
    addNewJobList as onAddNewJobList,
    updateJobList as onUpdateJobList,
    deleteJobList as onDeleteJobList,
} from "store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import {
    Col,
    Row,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Input,
    FormFeedback,
    Label,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Badge,
    Button,
} from "reactstrap";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";

const JobList = () => {
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [job, setJob] = useState(null);
	
	const [selectedMulti, setselectedMulti] = useState(null);

	  const optionGroup = [
	  {
		label: "Picnic",
		options: [
		  { label: "Mustard", value: "Mustard" },
		  { label: "Ketchup", value: "Ketchup" },
		  { label: "Relish", value: "Relish" }
		]
	  },
	  {
		label: "Camping",
		options: [
		  { label: "Tent", value: "Tent" },
		  { label: "Flashlight", value: "Flashlight" },
		  { label: "Toilet Paper", value: "Toilet Paper" }
		]
	  }
	  ];
		function handleMulti(selectedMulti) {
		setselectedMulti(selectedMulti);
	  }

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            jobId: (job && job.jobId) || '',
            jobTitle: (job && job.jobTitle) || '',
            companyName: (job && job.companyName) || '',
            location: (job && job.location) || '',
            experience: (job && job.experience) || '',
            position: (job && job.position) || '',
            type: (job && job.type) || '',
            status: (job && job.status) || '',
        },
        validationSchema: Yup.object({
            jobId: Yup.string().matches(
                /[0-9\.\-\s+\/()]+/,
                "Please Enter Valid Job Id"
            ).required("Please Enter Your Job Id"),
            jobTitle: Yup.string().required("Please Enter Your Job Title"),
            companyName: Yup.string().required("Please Enter Your Company Name"),
            location: Yup.string().required("Please Enter Your Location"),
            experience: Yup.string().required("Please Enter Your Experience"),
            position: Yup.string().required("Please Enter Your Position"),
            type: Yup.string().required("Please Enter Your Type"),
            status: Yup.string().required("Please Enter Your Status"),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                const updateJobList = {
                    id: job ? job.id : 0,
                    jobId: values.jobId,
                    jobTitle: values.jobTitle,
                    companyName: values.companyName,
                    location: values.location,
                    experience: values.experience,
                    position: values.position,
                    type: values.type,
                    postedDate: "02 June 2021",
                    lastDate: "25 June 2021",
                    status: values.status,
                };
                // update Job
                dispatch(onUpdateJobList(updateJobList));
                validation.resetForm();
            } else {
                const newJobList = {
                    id: Math.floor(Math.random() * (30 - 20)) + 20,
                    jobId: values["jobId"],
                    jobTitle: values["jobTitle"],
                    companyName: values["companyName"],
                    location: values["location"],
                    experience: values["experience"],
                    position: values["position"],
                    type: values["type"],
                    postedDate: "02 June 2021",
                    lastDate: "25 June 2021",
                    status: values["status"],
                };
                // save new Job
                dispatch(onAddNewJobList(newJobList));
                validation.resetForm();
            }
            toggle();
        },
    });

    const dispatch = useDispatch();

    const JobsJobsProperties = createSelector(
        (state) => state.JobReducer,
        (jobReducer) => ({
            jobs: jobReducer.jobs,
            loading: jobReducer.loading
        })
    );

    const {
        jobs, loading
    } = useSelector(JobsJobsProperties);

    const [isLoading, setLoading] = useState(loading)

    useEffect(() => {
        if (jobs && !jobs.length) {
            dispatch(onGetJobList());
        }
    }, [dispatch, jobs]);

    const toggle = () => {
        if (modal) {
            setModal(false);
            setJob(null);
        } else {
            setModal(true);
        }
    };

    const handleJobClick = arg => {
        const job = arg;
        setJob({
            id: job.id,
            jobId: job.jobId,
            jobTitle: job.jobTitle,
            companyName: job.companyName,
            location: job.location,
            experience: job.experience,
            position: job.position,
            type: job.type,
            status: job.status,
        });

        setIsEdit(true);

        toggle();
    };

    //delete Job
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (job) => {
        setJob(job);
        setDeleteModal(true);
    };

    const handleDeletejob = () => {
        if (job && job.id) {
            dispatch(onDeleteJobList(job.id));
            setDeleteModal(false);
        }
    };

    const columns = useMemo(
        () => [
            {
                header: '',
                accessorKey: "id",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    return <Link to="#" className="text-body fw-bold">{cellProps.row.original.id}</Link>
                }
            },
            {
                header: "Task",
                accessorKey: "jobTitle",
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                header: 'Description',
                accessorKey: "companyName",
                enableColumnFilter: false,
                enableSorting: false,
            },
            {
                header: 'Mandatory',
                enableColumnFilter: false,
                enableSorting: false,
                accessorKey: "location"
            },            
            {
                header: "Team",
                accessorKey: "team",
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
				  return (
					<div className="avatar-group">
					  {cellProps.row.original.team.map((member, index) =>
						<div className="avatar-group-item" key={index}>
						  {!member.img || member.img !== "Null" ?
							<>
							  <UncontrolledTooltip
								placement="right"
								target={`UncontrolledTooltipExample-${member.id}`}
							  >
								{member.fullname}
							  </UncontrolledTooltip>
							  <Link to="#" className="team-member d-inline-block" id={`UncontrolledTooltipExample-${member.id}`} >
								<img src={member.img} className="rounded-circle avatar-xs" alt="" />
							  </Link>
							</>
							:
							<>

							  <UncontrolledTooltip
								placement="right"
								target={`UncontrolledTooltipExample-${member.id}`}
							  >
								{member.name}
							  </UncontrolledTooltip>
							  <Link to="#" className="d-inline-block" id={`UncontrolledTooltipExample-${member.id}`} >
								<div className="avatar-xs">
								  <span className={`avatar-title rounded-circle bg-${member.color}  text-white font-size-16`}>{member.name}</span>
								</div>
							  </Link>
							</>
						  }
						</div>
					  )}
					</div>
				  )
				}
            },            
            {
                header: 'Action',
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-info"
                                    onClick={() => {
                                        const jobData = cellProps.row.original;
                                        handleJobClick(jobData);
                                    }}
                                    id={`edittooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-pencil-outline" />
                                    <UncontrolledTooltip placement="top" target={`edittooltip-${cellProps.row.original.id}`} >
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => {
                                        const jobData = cellProps.row.original;
                                        onClickDelete(jobData);
                                    }}
                                    id={`deletetooltip-${cellProps.row.original.id}`}
                                >
                                    <i className="mdi mdi-delete-outline" />
                                    <UncontrolledTooltip placement="top" target={`deletetooltip-${cellProps.row.original.id}`}>
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    );
                }
            },
        ],
        []
    );

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeletejob}
                onCloseClick={() => setDeleteModal(false)}
            />
			
            <div>
                <div>
                    
                    {
                        isLoading ? <Spinners setLoading={setLoading} />
                            :
                            <Row>
                                <Col lg="12">                                    
								<div className="d-flex align-items-center">
									<h5 className="mb-0 card-title flex-grow-1">Tasks</h5>
									<div className="flex-shrink-0">
										<Link to="#!" onClick={() => setModal(true)} className="btn btn-outline-success me-1">Add Task Template</Link>
										<Link to="#!" onClick={() => setModal(true)} className="btn btn-info me-1">Add Task</Link>
									</div>
								</div>
						   
								<TableContainer
									columns={columns}
									data={jobs || []}
									isCustomPageSize={false}
									isGlobalFilter={false}
									isJobListGlobalFilter={false}
									isPagination={true}
									SearchPlaceholder="Search for ..."
									tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline mt-4 border-top"
									pagination="pagination"
									paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
								/>                                       
                                </Col>
                            </Row>
                    }
                    <Modal isOpen={modal} toggle={toggle}>
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? "Edit Task" : "Add Task"}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <Row className="padding-b pt-0">
                                    <Col className="col-12">                                        
                                        <div className="mb-3">
                                            <Label>Name</Label>
                                            <Input
                                                name="jobTitle"
                                                type="text"
                                                placeholder="Task title"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.jobTitle || ""}
                                                invalid={
                                                    validation.touched.jobTitle && validation.errors.jobTitle ? true : false
                                                }
                                            />
                                            {validation.touched.jobTitle && validation.errors.jobTitle ? (
                                                <FormFeedback type="invalid">{validation.errors.jobTitle}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label>Description</Label>
                                            <textarea
											  id="message"
											  rows="5"
											  className="form-control rounded-bottom"
											  placeholder="Task details"
											></textarea>
                                        </div> 
										
										<Row className="mb-3">
										<Col>
										<Label>Team</Label>
										<Select
											value={selectedMulti}
											isMulti={true}
											onChange={() => {
											  handleMulti();
											}}
											options={optionGroup}
											className="select2-selection"
										  />
										</Col>  
										</Row>
										
                                       <Row className="mb-3">
										<Col lg={6}>
											<Label>Due date & time</Label>
                                            <Flatpickr
											  className="form-control d-block"
											  placeholder="dd M,yyyy"
											  options={{
												altInput: true,
												altFormat: "F j, Y",
												dateFormat: "Y-m-d"
											  }}
											/>  
										</Col>
										<Col lg={6}>
										
										</Col>
									   </Row>
                                       
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <Button color="success"
                                                type="submit"
                                                className="save-user"
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}


export default JobList;