import React from "react";
import { Card, Col, Container, Row, CardBody, CardTitle, Label, Button, Form, Input, InputGroup, FormFeedback } from "reactstrap";
import { Link } from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const CustomPDF = () => {

  return (
    <React.Fragment>
	
   <p className="fw-normal">Adjust what your client will see on this quote.</p>
		<Col className="form-check ms-3" lg={2}>
		  <input
			className="form-check-input"
			type="checkbox"
			value=""
			id="defaultCheck1"
			defaultChecked
		  />
		  <label
			className="form-check-label"
			htmlFor="defaultCheck1"
		  >
			Item Name
		  </label>
		</Col>
		<Col className="form-check" lg={2}>
		  <input
			className="form-check-input"
			type="checkbox"
			value=""
			id="defaultCheck1"
			defaultChecked
		  />
		  <label
			className="form-check-label"
			htmlFor="defaultCheck1"
		  >
			Description
		  </label>
		</Col>
		<Col className="form-check" lg={2}>
		  <input
			className="form-check-input"
			type="checkbox"
			value=""
			id="defaultCheck1"
			defaultChecked
		  />
		  <label
			className="form-check-label"
			htmlFor="defaultCheck1"
		  >
			Quantities
		  </label>
		</Col>
		<Col className="form-check" lg={2}>
		  <input
			className="form-check-input"
			type="checkbox"
			value=""
			id="defaultCheck2"
			defaultChecked
		  />
		  <label
			className="form-check-label"
			htmlFor="defaultCheck2"
		  >
			Unit Prices
		  </label>
		</Col>
		<Col className="form-check" lg={2}>
		  <input
			className="form-check-input"
			type="checkbox"
			value=""
			id="defaultCheck3"
			defaultChecked
		  />
		  <label
			className="form-check-label"
			htmlFor="defaultCheck3"
		  >
			Line Total
		  </label>
		</Col>
		
	 
    </React.Fragment>
  )
}

export default CustomPDF;
