import PropTypes from 'prop-types'
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Card, CardBody, CardTitle, Label, Form, Input, InputGroup, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, 
Table, Badge, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown, Offcanvas, OffcanvasHeader, OffcanvasBody, AccordionBody, AccordionHeader, AccordionItem, 
Collapse, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledAccordion, UncontrolledCollapse, Alert} from "reactstrap";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react"
import { connect } from "react-redux"
import { useFormik } from "formik";
import * as Yup from 'yup';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Filter from "../Shortcut/filter";
import LightData from "./LightData"
const LoadingContainer = () => <div>Loading...</div>

const MapsGoogle = props => {
	
  const selectedPlace = {}

  function onMarkerClick() {
    alert("You clicked in this marker")
  }

  const [singlebtn, setSinglebtn] = useState(false)	
  document.title="Map - SYANPRO";
  const [isRight, setIsRight] = useState(false);
  const toggleRightCanvas = () => {
	setIsRight(!isRight);
  };
  
  const [modal_standard, setmodal_standard] = useState(false);
  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  
  const [customchkPrimary, setcustomchkPrimary] = useState(true);
  const [customchkSuccess, setcustomchkSuccess] = useState(true);
  const [customchkInfo, setcustomchkInfo] = useState(true);
  const [customchkWarning, setcustomchkWarning] = useState(true);
  const [customchkDanger, setcustomchkDanger] = useState(true);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}         
		  
		  <Row>
			<Col lg="6">
			<Breadcrumbs title="Services" breadcrumbItem="Map" />
			</Col>
			<Col lg="6" className="neg-margin">
				<div className="d-flex gap-2 float-end">
				<Button color="primary" outline className="btn me-2" onClick={toggleRightCanvas}><i className="bx bx-filter-alt font-size-16 align-middle me-2"></i> Filter</Button>				
				<Link to="/schedule"><Button color="primary" className="btn me-2"><i className="bx bx-calendar-event font-size-16 align-middle me-2"></i> Calendar</Button></Link>
				<Link to="/map"><Button color="secondary" className="btn me-2"><i className="bx bx-detail font-size-16 align-middle me-2"></i> List</Button></Link>
				<Button color="success" className="btn me-2"
				onClick={(e) => {
				e.preventDefault();
				tog_standard();
				}}
				><i className="bx bx-plus font-size-16 align-middle me-2"></i> New Event</Button>
				<Dropdown
				 isOpen={singlebtn}
				 toggle={() => setSinglebtn(!singlebtn)}
				 className="mt-4 mt-sm-0"
				>
				<DropdownToggle className="btn btn-primary" caret>
				  <i className="bx bx-dots-horizontal-rounded font-size-16 align-middle me-2"></i>
				  More Actions <i className="mdi mdi-chevron-down" />
				</DropdownToggle>
				<DropdownMenu>
				  <DropdownItem>Quotes Report</DropdownItem>
				  <DropdownItem>Quote Settings</DropdownItem>
				  <DropdownItem>Email Template</DropdownItem>
				</DropdownMenu>
				</Dropdown>	
				</div>
			</Col>
		  </Row>
		  
		   <Modal
			  size="lg"
			  isOpen={modal_standard}
			  toggle={() => {
				tog_standard();
			  }}
			  centered
			>
			  <div>                       
				<button
				  type="button"
				  onClick={() => {
					setmodal_standard(false);
				  }}
				  className="close"
				  data-dismiss="modal"
				  aria-label="Close"
				>
				  <span aria-hidden="true">&times;</span>
				</button>
			  </div>
			  <div className="modal-body">
			   
			  </div>
			   <div className="modal-footer">
			   <Button color="primary" outline className="btn float-end me-1"
			   onClick={() => {
					tog_standard();
				  }}
			   >
			   Cancel
			   </Button>
			   <Button color="success" outline className="btn float-end me-1">Save and Close</Button>			   
			   <Link to="/new-job"><Button color="success" className="btn float-end me-3">Save and Continue</Button></Link>
				
			  </div>
			</Modal>
		  	
		
		   			  
            <Card className="mainCard">
            <CardBody className="ps-0 pt-0 pe-0 pb-0">
				
			<Card className="mb-0">
			<CardBody>
			<Row>
				<Col lg={3}>
					<Alert color="danger mb-0 bgWhite">
                      <div className="form-check form-check-danger">
						<input
						  type="checkbox"
						  className="form-check-input"
						  id="customCheckcolor5"
						  checked={customchkDanger}
						  onChange={() => {
							setcustomchkDanger(!customchkDanger)
						  }}
						/>
						<label
						  className="form-check-label"
						  htmlFor="customCheckcolor5"
						>
						  Pending Dispatch
						</label>
						<Badge color="danger" className="float-end font-size-12">0</Badge>
					  </div>
                    </Alert>
				</Col>
				<Col lg={3}>
					<Alert color="primary mb-0 bgWhite">					
					<div className="form-check form-check-primary">
					<input
					  type="checkbox"
					  className="form-check-input"
					  id="customCheckcolor1"
					  checked={customchkPrimary}
					  onChange={() => {
						setcustomchkPrimary(!customchkPrimary)
					  }}
					/>
					<label
					  className="form-check-label"
					  htmlFor="customCheckcolor1"
					>
					  Dispatched Jobs
					</label>
					<Badge color="primary" className="float-end font-size-12">0</Badge>	
				    </div>					
                    </Alert>
				</Col>
				<Col lg={3}>
					<Alert color="warning mb-0 bgWhite">
                      <div className="form-check form-check-warning">
						<input
						  type="checkbox"
						  className="form-check-input"
						  id="customCheckcolor4"
						  checked={customchkWarning}
						  onChange={() => {
							setcustomchkWarning(!customchkWarning)
						  }}
						/>
						<label
						  className="form-check-label"
						  htmlFor="customCheckcolor4"
						>
						  Jobs Underway						  
						</label>
						<Badge color="warning" className="float-end font-size-12">0</Badge>
					  </div>
                    </Alert>
				</Col>
				<Col lg={3}>
					<Alert color="success mb-0 bgWhite">
                       <div className="form-check form-check-success">
						<input
						  type="checkbox"
						  className="form-check-input"
						  id="customCheckcolor2"
						  checked={customchkSuccess}
						  onChange={() => {
							setcustomchkSuccess(!customchkSuccess)
						  }}
						/>
						<label
						  className="form-check-label"
						  htmlFor="customCheckcolor2"
						>
						  Submitted Jobs						  
						</label>
						<Badge color="success" className="float-end font-size-12">0</Badge>
					  </div>
                    </Alert>
				</Col>
			</Row>	
			</CardBody>
			</Card>
				
				<Row className="map-height">	
				<Col lg={8}> 
				<div
                    id="gmaps-markers"
                    className="gmaps"
                    style={{ position: "relative" }}
                  >
                    <Map
                      google={props.google}
                      style={{ width: "100%", height: "500px" }}
                      zoom={14}
                    >
                      <Marker
                        title={"The marker`s title will appear as a tooltip."}
                        name={"SOMA"}
                        position={{ lat: 37.778519, lng: -122.40564 }}
                      />
                      <Marker name={"Dolores park"} />
                      <InfoWindow>
                        <div>
                          <h1>{selectedPlace.name}</h1>
                        </div>
                      </InfoWindow>
                    </Map>
                  </div>
				</Col>
				<Col lg={4}> 
				 <UncontrolledAccordion defaultOpen="1" flush>
					<AccordionItem>
					  <AccordionHeader targetId="1">Jobs</AccordionHeader>
					  <AccordionBody accordionId="1">
						<div className="text-muted">
						  
						  <div className="search-box mb-2">
							<div className="position-relative">
							  <input
								type="text"
								className="form-control border-light rounded"
								placeholder="Job, Customer or Address"
							  />
							  <i className="bx bx-search-alt search-icon"></i>
							</div>
						  </div>
						  
						  <Alert color="secondary mb-0 bgWhite padding-a">
						  
						  <div className="mb-3">
						  <span className="fw-bolder font-size-16">Total Jobs: 3</span>
						  <Label className="float-end fw-normal font-size-12">Show All 
						  <input
                            className="form-check-input ms-2"
                            type="checkbox"
                            value=""
                            id="defaultCheck1"
                          />
						  </Label>
						  </div>
						  
						  <Alert color="secondary mb-3 bgWhite padding-a">
						  <input
                            className="form-check-input float-end"
                            type="checkbox"
                            value=""
                            id="defaultCheck1"
                          />
						  <p className="mb-1 fw-bold">Mindbridge Business Solutions</p> 
						  <div className="text-muted font-size-12">
						  1300 Constitution Ave NW, Washington, DC..
						  </div>
						  <Badge color="danger" className="font-size-12 fw-normal mt-1">Not Assigned</Badge>
						  </Alert>
						  
						   <Alert color="secondary mb-0 bgWhite padding-a">
						  <input
                            className="form-check-input float-end"
                            type="checkbox"
                            value=""
                            id="defaultCheck1"
                          />
						  <p className="mb-1 fw-bold">Relaince General Contractor</p> 
						  <div className="text-muted font-size-12">
						  2800 Subway Street TYI NW, Washington, DC...
						  </div>
						  <Badge color="success" className="font-size-12 fw-normal mt-1">Assigned</Badge>
						  </Alert>
						  
						  
						  </Alert>
						  
						</div>
					  </AccordionBody>
					</AccordionItem>
					<AccordionItem>
					  <AccordionHeader targetId="2">Team</AccordionHeader>
					  <AccordionBody accordionId="2">
						
						<div className="search-box mb-2">
						<div className="position-relative">
						  <input
							type="text"
							className="form-control border-light rounded"
							placeholder="Agent Name"
						  />
						  <i className="bx bx-search-alt search-icon"></i>
						</div>
					    </div>
						
					  </AccordionBody>
					</AccordionItem>					
				  </UncontrolledAccordion>
				</Col>					
				</Row>                  
                </CardBody>
              </Card>
            
        </Container>
      </div>
	  
	  
	  <Offcanvas
		isOpen={isRight}
		direction="end"
		toggle={toggleRightCanvas}>
		<OffcanvasHeader toggle={toggleRightCanvas}>
			Filters
		</OffcanvasHeader>
		<OffcanvasBody>
			<Filter />
		</OffcanvasBody>
	  </Offcanvas>
	  
    </React.Fragment>
  )
}


MapsGoogle.propTypes = {
  google: PropTypes.object
}

export default connect(
  null,
  {}
)(
  GoogleApiWrapper({
    apiKey: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    LoadingContainer: LoadingContainer,
    v: "3",
  })(MapsGoogle)
)
