import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Card, CardBody, CardTitle, Label, Form, Input, InputGroup, FormFeedback, Modal, ModalHeader, 
ModalBody, ModalFooter, Table, Badge, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown, UncontrolledDropdown, Alert} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

const NewJob = () => {

  document.title="New Job - SYANPRO";
  const [singlebtn, setSinglebtn] = useState(false)	
  const [modal_standard, setmodal_standard] = useState(false);
  const [editQuoteNo, setShow] = useState(false);
  
  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }
    function tog_fullscreen() {
    setmodal_fullscreen(!modal_fullscreen);
    removeBodyCss();
  }

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  return (
    <React.Fragment>                      
		  
				 <div className="padding-b">
					
					<Row className="mb-4">
						<Col>
						<h3>New Customer</h3>
						</Col>
					</Row>					
					
					
					<Row className="mb-3">
					<Col sm={6}>
						<Label htmlFor="formrow-InputState">Customer Type</Label>
					   <div className="row">
						<div className="col-sm-10">
						<select
						name="state"
						id="formrow-InputState"
						className="form-control"                            
					    >
						<option >Select Type</option>
						<option>...</option>
					    </select>
						</div>
						<div className="col-sm-2 ps-0 ms-0">
						<Button color="warning" className="btn"><i className="bx bx-plus font-size-16 align-middle"></i></Button>
						</div>
					   </div>
					</Col>					
					<Col sm={6}>
						<Label>Customer No. #4</Label>
						<div className="row">
						{ editQuoteNo &&
						<div className="col-sm-7 me-1 ms-0 pe-0">
						<Input
						  name="firstname"
						  type="text"
						  className="form-control"
						  id="horizontal-firstname-Input"
						  placeholder="New Number"
						/>	
						</div>
						}
						<div className="col-sm-4 ms-0">
						<Button color="primary" outline className="btn" onClick={()=>setShow(!editQuoteNo)}>{editQuoteNo === true ? 'Update' : 'Change'}</Button>
						</div>
						</div>
					</Col>
					</Row>
					
					<Row className="mb-3">
						  <Label htmlFor="formrow-InputCity">Customer Name</Label>
						  <Col sm={6}>
							<Input
							  name="firstname"
							  type="text"
							  className="form-control"
							  id="horizontal-firstname-Input"
							  placeholder="Customer Name"
							/>								
						  </Col>
						  <Col sm={6}>
							<Input
							  name="firstname"
							  type="text"
							  className="form-control text-end ar-type"
							  id="horizontal-firstname-Input"
							  placeholder="مسمى وظيفي"
							/>								
						  </Col>
						</Row>	

					<Row className="mb-4">
					<Col sm={4}>
					  <Label htmlFor="formrow-InputState">VAT No.</Label>
					  <Input
						  name="firstname"
						  type="text"
						  className="form-control"
						  id="horizontal-firstname-Input"
						  placeholder="VAT Number"
						/>
					</Col>					
					<Col sm={4}>
						<Label htmlFor="formrow-InputState">ID Type</Label>					   
						<select
						name="state"
						id="formrow-InputState"
						className="form-control"                            
					    >
						<option >Company Registration</option>
						<option>700 Number</option>
					    </select>
					</Col>
					<Col sm={4}>
					  <Label htmlFor="formrow-InputState">ID Number</Label>
					  <Input
						  name="firstname"
						  type="text"
						  className="form-control"
						  id="horizontal-firstname-Input"
						  placeholder="ID No."
						/>
					</Col>	
					</Row>
					
					 <span className="placeholder col-12 placeholder-xs bg-light mb-3"></span>	
					 
					<Row className="mb-1">
					  <Col sm={6}>
					  <Label htmlFor="formrow-InputCity">Street 1</Label>
						<Input
						  name="firstname"
						  type="text"
						  className="form-control"
						  id="horizontal-firstname-Input"
						  placeholder="Address Line 1"
						/>								
					  </Col>
					  <Col sm={6}>
					  <Label htmlFor="formrow-InputCity">Street 2</Label>
						<Input
						  name="firstname"
						  type="text"
						  className="form-control"
						  id="horizontal-firstname-Input"
						  placeholder="Address Line 2"
						/>								
					  </Col>
					</Row>	
					 
					<Row>
					<Col lg={3} className="me-0 pe-0">
						<div className="form-floating mb-3">
						  <input type="text" name="name" className="form-control group-input-a" id="companycr" placeholder="Enter Name" />
						  <label htmlFor="companycr">Building No.</label>						 
						</div>
					</Col>
					<Col lg={3} className="ms-0 me-0 ps-0 pe-0">
						<div className="form-floating mb-3">
						  <input type="text" name="name" className="form-control group-input-ab" id="companycr" placeholder="Enter Name" />
						  <label htmlFor="companycr">Zip Code</label>						 
						</div>
					</Col>
					<Col lg={3} className="ms-0 me-0 ps-0 pe-0">
						<div className="form-floating mb-3">
						  <input type="text" name="name" className="form-control group-input-ab" id="companycr" placeholder="Enter Name" />
						  <label htmlFor="companycr">City</label>						 
						</div>
					</Col>
					<Col lg={3} className="ms-0 ps-0">
						<div className="form-floating mb-3">
						  <input type="text" name="name" className="form-control group-input-b" id="companycr" placeholder="Enter Name" />
						  <label htmlFor="companycr">Subdevision</label>						 
						</div>
					</Col>
					</Row>
					
					<Row>
					<Col lg={4}>
					<Label htmlFor="formrow-InputState">District</Label>
					  <select
						name="state"
						id="formrow-InputState"
						className="form-control"                            
					  >
						<option >Al Olaya</option>
						<option>...</option>
					  </select> 
					</Col>
					<Col lg={4}>
					<Label htmlFor="formrow-InputState">Province</Label>
                          <select
                            name="state"
                            id="formrow-InputState"
                            className="form-control"                            
                          >
                            <option >Western</option>
                            <option>...</option>
                          </select>
					</Col>
					<Col lg={4}>
					<Label>Country</Label>
						<select
						name="state"
						id="formrow-InputState"
						className="form-control"                            
					   >
						<option >Saudi Arabia</option>
						<option>...</option>
					   </select> 
					</Col>
					</Row>
						
					
					
				</div>	
					
			
    </React.Fragment>
  )
}

export default NewJob;
