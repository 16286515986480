import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Card, CardBody, CardTitle, Label, Form, Input, InputGroup, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, 
Table, Badge, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown, Offcanvas, OffcanvasHeader, OffcanvasBody} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from 'yup';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import QuotesListing from "./quotes-listing";
import Filter from "../Shortcut/filter";

const Quotes = () => {

  const [singlebtn, setSinglebtn] = useState(false)	
  document.title="Quotes - SYANPRO";
  const [isRight, setIsRight] = useState(false);
  const toggleRightCanvas = () => {
	setIsRight(!isRight);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}         
		  
		  <Row>
			<Col lg="6">
			<Breadcrumbs title="Services" breadcrumbItem="Quotes" />
			</Col>
			<Col lg="6" className="neg-margin">
				<div className="d-flex gap-2 float-end">
				<Button color="primary" outline className="btn me-2" onClick={toggleRightCanvas}><i className="bx bx-filter-alt font-size-16 align-middle me-2"></i> Filter</Button>				
				<Link to="/newquote"><Button color="success" className="btn me-2"><i className="bx bx-plus font-size-16 align-middle me-2"></i> New Quote</Button></Link>
				<Dropdown
				 isOpen={singlebtn}
				 toggle={() => setSinglebtn(!singlebtn)}
				 className="mt-4 mt-sm-0"
				>
				<DropdownToggle className="btn btn-primary" caret>
				  <i className="bx bx-dots-horizontal-rounded font-size-16 align-middle me-2"></i>
				  More Actions <i className="mdi mdi-chevron-down" />
				</DropdownToggle>
				<DropdownMenu>
				  <DropdownItem>Quotes Report</DropdownItem>
				  <DropdownItem>Quote Settings</DropdownItem>
				  <DropdownItem>Email Template</DropdownItem>
				</DropdownMenu>
				</Dropdown>	
				</div>
			</Col>
		  </Row>
		  		  
		   <Row>	
            <Col lg={12}> 			  
              <QuotesListing />
            </Col>			
          </Row>
        </Container>
      </div>
	  
	  
	  <Offcanvas
		isOpen={isRight}
		direction="end"
		toggle={toggleRightCanvas}>
		<OffcanvasHeader toggle={toggleRightCanvas}>
			Filters
		</OffcanvasHeader>
		<OffcanvasBody>
			<Filter />
		</OffcanvasBody>
	  </Offcanvas>
	  
    </React.Fragment>
  )
}

export default Quotes;
