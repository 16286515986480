import React, { useState } from "react";
import { Label, AccordionBody, AccordionHeader, AccordionItem, Card, CardBody, CardText, CardTitle, Col, Collapse, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledAccordion, UncontrolledCollapse, Form, Input, InputGroup, FormFeedback } from "reactstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const CustomerShortcut = () => {  
  
  
  //Floating labels forms
  const floatingformik = useFormik({
    initialValues: {
      name: "",
      email: "",
      select: "",
      check: ""
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required"),
      email: Yup.string().email().matches(/^(?!.*@[^,]*,)/).required("Please Enter Your Email"),
      select: Yup.string().required("This field is required"),
      check: Yup.string().required("This field is required"),
    }),

    onSubmit: (values) => {
      // console.log("value", values.password);
    },
  });
  
    //Horizontal form 
  const horizontalformik = useFormik({
    initialValues: {
      firstname: "",
      email: "",
      password: "",
      check: ""
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("This field is required"),
      email: Yup.string().email().matches(/^(?!.*@[^,]*,)/).required("Please Enter Your Email"),
      password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .matches(RegExp('(.*[a-z].*)'), 'At least lowercase letter')
        .matches(RegExp('(.*[A-Z].*)'), 'At least uppercase letter')
        .matches(RegExp('(.*[0-9].*)'), 'At least one number')
        .required("This field is required"),
      check: Yup.string().required("This field is required"),
    }),

    onSubmit: (values) => {
      // console.log("value", values.password);
    },
  });
  
  return (
    <React.Fragment>
					
						
						
		<h4>Create a New Customer</h4>
		 <UncontrolledAccordion defaultOpen="1" flush>
			<AccordionItem>
			  <AccordionHeader targetId="1">Customer details</AccordionHeader>
			  <AccordionBody accordionId="1">
				<div className="text-muted">
				   <Form>
                    <div className="form-floating mb-3">
                      <input type="text" name="name" className="form-control" id="companynameeng" placeholder="Enter Name"
                        value={floatingformik.values.name}
                        onChange={floatingformik.handleChange}
                        onBlur={floatingformik.handleBlur}
                      />
                      <label htmlFor="companynameeng">Company Name</label>
                      {
                        floatingformik.errors.name && floatingformik.touched.name ? (
                          <span className="text-danger">{floatingformik.errors.name}</span>
                        ) : null
                      }
                    </div>
					<div className="form-floating mb-3 text-end align-end">
                      <input type="text" name="namear" className="form-control" id="companynamear" placeholder="Enter Name"
                        value={floatingformik.values.namear}
                        onChange={floatingformik.handleChange}
                        onBlur={floatingformik.handleBlur}
                      />
                      <label htmlFor="companynamear" className="ar-type">اسم الشركة</label>
                      {
                        floatingformik.errors.name && floatingformik.touched.name ? (
                          <span className="text-danger ar-type">هذه الخانة مطلوبه</span> //{floatingformik.errors.name}
                        ) : null
                      }
                    </div>
					
					<Row>
					  <Col md={6}>
						<div className="form-floating mb-3">
						  <input type="text" name="name" className="form-control" id="companycr" placeholder="Enter Name"
							value={floatingformik.values.name}
							onChange={floatingformik.handleChange}
							onBlur={floatingformik.handleBlur}
						  />
						  <label htmlFor="companycr">Company Registration No.</label>
						  {
							floatingformik.errors.name && floatingformik.touched.name ? (
							  <span className="text-danger">{floatingformik.errors.name}</span>
							) : null
						  }
						</div>
					  </Col>
					  <Col md={6}>
						<div className="form-floating mb-3">
						  <input type="text" name="name" className="form-control" id="companycr" placeholder="Enter Name"
							value={floatingformik.values.name}
							onChange={floatingformik.handleChange}
							onBlur={floatingformik.handleBlur}
						  />
						  <label htmlFor="companycr">VAT No.</label>
						  {
							floatingformik.errors.name && floatingformik.touched.name ? (
							  <span className="text-danger">{floatingformik.errors.name}</span>
							) : null
						  }
						</div>
					  </Col>
					</Row>
					
					
                    <Row>
                      <Col md={6}>
                        <div className="form-floating mb-3">
                          <input type="email" name="email" className="form-control" id="floatingemailInput" placeholder="Enter Email address"
                            value={floatingformik.values.email}
                            onChange={floatingformik.handleChange}
                            onBlur={floatingformik.handleBlur}
                          />
                          <label htmlFor="floatingemailInput">Email address</label>
                          {
                            floatingformik.errors.email && floatingformik.touched.email ? (
                              <span className="text-danger">{floatingformik.errors.email}</span>
                            ) : null
                          }
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-floating mb-3">
                          <select className="form-select" name="select"
                            value={floatingformik.values.select}
                            onChange={floatingformik.handleChange}
                            onBlur={floatingformik.handleBlur}>
                            <option defaultValue="0">Open this select menu</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                          <label htmlFor="floatingSelectGrid">Works with selects</label>
                          <div>
                            {
                              floatingformik.errors.select && floatingformik.touched.select ? (
                                <span className="text-danger">{floatingformik.errors.select}</span>
                              ) : null
                            }
                          </div>
                        </div>
                      </Col>
                    </Row>
					
					
					
					<div>
						<InputGroup className="mb-3">
						  <Label className="input-group-text">Amount</Label>
						  <select
							defaultValue="1"
							className="form-select"
							style={{ maxWidth: "90px" }}
						  >
							<option value="1" >
							  BTC
							</option>
							<option value="2">ETH</option>
							<option value="3">LTC</option>
						  </select>
						  <Input type="text" className="form-control" />
						</InputGroup>
					</div>
					
										
					
					<div>
						<InputGroup className="mb-3">
						  <Label className="input-group-text">Amount</Label>
						  <select
							defaultValue="1"
							className="form-select"
							style={{ maxWidth: "90px" }}
						  >
							<option value="1" >
							  BTC
							</option>
							<option value="2">ETH</option>
							<option value="3">LTC</option>
						  </select>
						  <Input type="text" className="form-control" />
						</InputGroup>
					</div>

                   
                  </Form>
				</div>
			  </AccordionBody>
			</AccordionItem>
			<AccordionItem>
			  <AccordionHeader targetId="2">Automated notifications</AccordionHeader>
			  <AccordionBody accordionId="2">
				  <div className="text-muted">
					
					<div className="d-flex mb-3">						
						<div className="ms-2 flex-grow-1">
							<h6 className="mb-0 font-size-15">
								<label className="text-body mb-0" htmlFor="quotefu">Quote follow-up</label>
							</h6>
							<p className="text-muted mb-0">Follow up on an outstanding quote. <Link>Settings</Link></p>							
						</div>	
						<div className="form-check form-switch form-switch-lg">
							<input
                            type="checkbox"
                            className="form-check-input"
                            id="quotefu"
                            defaultChecked
                            />
						</div>						
					</div>
					
					<div className="d-flex mb-3">						
						<div className="ms-2 flex-grow-1">
							<h6 className="mb-0 font-size-15">
								<label className="text-body mb-0" htmlFor="sitevisitfu">Assessment and visit reminders</label>
							</h6>
							<p className="text-muted mb-0">Remind your client of an upcoming assessment or visit. <Link>Settings</Link></p>							
						</div>	
						<div className="form-check form-switch form-switch-lg">
							<input
                            type="checkbox"
                            className="form-check-input"
                            id="sitevisitfu"
                            defaultChecked
                            />
						</div>						
					</div>
					
					<div className="d-flex mb-3">						
						<div className="ms-2 flex-grow-1">
							<h6 className="mb-0 font-size-15">
								<label className="text-body mb-0" htmlFor="jobfu">Job follow-up</label>
							</h6>
							<p className="text-muted mb-0">Follow up when you close a job. <Link>Settings</Link></p>							
						</div>	
						<div className="form-check form-switch form-switch-lg">
							<input
                            type="checkbox"
                            className="form-check-input"
                            id="jobfu"
                            defaultChecked
                            />
						</div>						
					</div>
					
					<div className="d-flex">						
						<div className="ms-2 flex-grow-1">
							<h6 className="mb-0 font-size-15">
								<label className="text-body mb-0" htmlFor="invoicefu">Invoice follow-up</label>
							</h6>
							<p className="text-muted mb-0">Follow up on an overdue invoice. <Link>Settings</Link></p>							
						</div>	
						<div className="form-check form-switch form-switch-lg">
							<input
                            type="checkbox"
                            className="form-check-input"
                            id="invoicefu"
                            defaultChecked
                            />
						</div>						
					</div>
					
				  </div>				
			  </AccordionBody>
			</AccordionItem>
			<AccordionItem>
			  <AccordionHeader targetId="3">Additional details</AccordionHeader>
			  <AccordionBody accordionId="3">
				<div className="text-muted">
				  
				  <Row className="mb-4">
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-sm-3 col-form-label"
                      >
                        Custom Field
                      </Label>
                      <Col sm={9}>
                        <Input
                          name="firstname"
                          type="text"
                          className="form-control"
                          id="horizontal-firstname-Input"
                          placeholder="Enter Your"
                          value={horizontalformik.values.firstname}
                          onChange={horizontalformik.handleChange}
                          onBlur={horizontalformik.handleBlur}
                          invalid={
                            horizontalformik.touched.firstname && horizontalformik.errors.firstname ? true : false
                          }
                        />
                        {
                          horizontalformik.errors.firstname && horizontalformik.touched.firstname ? (
                            <FormFeedback type="invalid">{horizontalformik.errors.firstname}</FormFeedback>
                          ) : null
                        }
                      </Col>
                    </Row>
				  
				</div>
			  </AccordionBody>
			</AccordionItem>
		  </UncontrolledAccordion>
						
                      
                 
	 
    </React.Fragment>
  )
}

export default CustomerShortcut;
