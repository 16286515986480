import React, { useRef, useState } from "react";
import { Card, Col, Container, Row, CardBody, CardTitle, Label, Button, Form, Input, InputGroup, FormFeedback } from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Filter = () => {
	
  const [selectedMulti, setselectedMulti] = useState(null);
   function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti);
  }
  const optionGroup = [
  {
    label: "Picnic",
    options: [
      { label: "Mustard", value: "Mustard" },
      { label: "Ketchup", value: "Ketchup" },
      { label: "Relish", value: "Relish" }
    ]
  },
  {
    label: "Camping",
    options: [
      { label: "Tent", value: "Tent" },
      { label: "Flashlight", value: "Flashlight" },
      { label: "Toilet Paper", value: "Toilet Paper" }
    ]
  }
];

  return (
    <React.Fragment>
	<div className="padding-a">
	
   <p className="fw-normal">Select the options and save as favourite.</p>
   
   <Row className="mb-3">
   <Col lg={6}>
	  <Label className="input-text">Document No.</Label>
	  <Input type="text" className="form-control" />		
	</Col>
	<Col lg={6}>
	  <Label className="input-text">Reference</Label>
	  <Input type="text" className="form-control" />		
	</Col>
	</Row>
	
	<Row className="mb-3">
    <Col lg={12}>
	  <Label className="input-text">Customer</Label>
	  <Input type="text" className="form-control" />		
	</Col>
	</Row>
	
	<Row className="mb-3">
	<Col lg={6}>
	  <Label className="input-text">Status</Label>
	   <Select
		value={selectedMulti}
		isMulti={true}
		onChange={() => {
		  handleMulti();
		}}
		options={optionGroup}
		className="select2-selection"
	   />	
	</Col>
	<Col lg={6}>
	  <Label className="input-text">Periods</Label>
	  <Input type="text" className="form-control" />		
	</Col>
	</Row>
	
	<Row className="mb-3">
	<Col lg={6}>
	  <Label className="input-text">From Date</Label>
	  <Input type="text" className="form-control" />		
	</Col>
	<Col lg={6}>
	  <Label className="input-text">To Date</Label>
	  <Input type="text" className="form-control" />		
	</Col>
	</Row>
	
	<Row className="mb-3">
    <Col lg={12}>
	  <Label className="input-text">Created by</Label>
	  <Input type="text" className="form-control" />		
	</Col>
	</Row>
   
		<Col className="form-check" lg={12}>
		  <input
			className="form-check-input"
			type="checkbox"
			value=""
			id="defaultCheck1"
			defaultChecked
		  />
		  <label
			className="form-check-label"
			htmlFor="defaultCheck1"
		  >
			Set as my favourite
		  </label>
		</Col>
		
		<Button color="success" className="btn float-end">Search</Button>
		<Button color="primary" outline className="btn float-end me-3">Cancel</Button>
		
	 </div>
    </React.Fragment>
  )
}

export default Filter;
