import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Card, CardBody, CardTitle, Label, Form, Input, InputGroup, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, Table, Badge, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown, UncontrolledDropdown} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import NotesAttachments from "../Shortcut/notes-attachments";

const NewJob = () => {

  document.title="New Expense - SYANPRO";
  const [singlebtn, setSinglebtn] = useState(false);
  const [editQuoteNo, setShow] = useState(false);
 

  return (
    <React.Fragment>                      
		  
				 <div className="padding-b">
					<Row className="mb-3">
					<Col><h3 className="text-body">New expense</h3></Col>
					</Row>
					<Row className="mb-3">										
					<Col sm={6}>
						<Label>Expense No. #4</Label>
						<div className="row">
						{ editQuoteNo &&
						<div className="col-sm-7 me-1 ms-0 pe-0">
						<Input
						  name="firstname"
						  type="text"
						  className="form-control"
						  id="horizontal-firstname-Input"
						  placeholder="New Number"
						/>	
						</div>
						}
						<div className="col-sm-4 ms-0">
						<Button color="primary" outline className="btn" onClick={()=>setShow(!editQuoteNo)}>{editQuoteNo === true ? 'Update' : 'Change'}</Button>
						</div>
						</div>
					</Col>
					</Row>
					
					
					<Row className="mb-4">
                      <Col lg={6}>
                       <Label htmlFor="formrow-InputState">Date</Label>
						<Input
						  name="firstname"
						  type="text"
						  className="form-control"
						  id="horizontal-firstname-Input"
						  placeholder="Item Name"
						/>	
                     </Col>

                      <Col lg={6}>                       
					  <Label>Job No.</Label>
						<select className="form-control">
						  <option>Select Job No.</option>
						  <option>JOB524 - Mar 05, 2024</option>
						  <option>JOB523 - Mar 03, 2024</option>
						</select>
                      </Col>
                    </Row>
					
						
					<Row className="mb-1">
					  <Label htmlFor="formrow-InputCity">Item Name</Label>
					  <Col sm={6}>
						<Input
						  name="firstname"
						  type="text"
						  className="form-control"
						  id="horizontal-firstname-Input"
						  placeholder="Item Name"
						/>								
					  </Col>
					  <Col sm={6}>
						<Input
						  name="firstname"
						  type="text"
						  className="form-control text-end ar-type"
						  id="horizontal-firstname-Input"
						  placeholder="مسمى وظيفي"
						/>								
					  </Col>
					</Row>	
						
					<Row className="mb-3">
					<Col>
					<textarea
					  id="message"
					  rows="4"
					  className="form-control rounded-bottom"
					  placeholder="Description"
					></textarea>
					</Col>
					</Row>

					<Row className="mb-3">
					<Col lg={6}>
					<Label htmlFor="formrow-InputState">Reimburse to</Label>
					  <select
						name="state"
						id="formrow-InputState"
						className="form-control"                            
					  >
						<option >Not reimbursable</option>
						<option>...</option>
					  </select> 
					</Col>					
					<Col lg={6}>
					<Label>Total</Label>
					<InputGroup>
                        <div className="input-group-text">SAR</div>
                        <input type="text" name="username" className="form-control" placeholder="0.00" value="0.00" />
                      </InputGroup> 
					</Col>
					</Row>
					
					<Row>
					<Col>
						<NotesAttachments />
					</Col>
					</Row>
					
				</div>	
					
			
    </React.Fragment>
  )
}

export default NewJob;
