import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Card, CardBody, CardTitle, Label, Form, Input, InputGroup, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, Table, Badge, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown, UncontrolledDropdown} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import NotesAttachments from "../Shortcut/notes-attachments";

const NewTimesheet = () => {

  document.title="New Expense - SYANPRO";
  const [singlebtn, setSinglebtn] = useState(false);
  const [editQuoteNo, setShow] = useState(false);
 

  return (
    <React.Fragment>                      
		  
				 <div className="padding-b">
					<Row className="mb-3">
					<Col><h3 className="text-body">New Timesheet</h3></Col>
					</Row>
					<Row className="mb-3">										
					<Col sm={6}>
						<Label>Timesheet No. #4</Label>
						<div className="row">
						{ editQuoteNo &&
						<div className="col-sm-7 me-1 ms-0 pe-0">
						<Input
						  name="firstname"
						  type="text"
						  className="form-control"
						  id="horizontal-firstname-Input"
						  placeholder="New Number"
						/>	
						</div>
						}
						<div className="col-sm-4 ms-0">
						<Button color="primary" outline className="btn" onClick={()=>setShow(!editQuoteNo)}>{editQuoteNo === true ? 'Update' : 'Change'}</Button>
						</div>
						</div>
					</Col>
					</Row>
					
					
					<Row className="mb-4">
                      <Col lg={4}>
                       <Label htmlFor="formrow-InputState">Date</Label>
						<Input
						  name="firstname"
						  type="text"
						  className="form-control"
						  id="horizontal-firstname-Input"
						  placeholder="Mar 15, 2024"
						/>	
                     </Col>

                      <Col lg={8}>                       
					  <Label>Assignee</Label>
						<select className="form-control">
						  <option>Select Assignee</option>
						  <option>Fazal Rahman</option>
						  <option>Vipin Ponnamkandi</option>
						</select>
                      </Col>
                    </Row>
					
						
					<Row className="mb-4">
					<Col>
					<div className="table-responsive mb-2">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>Job No.</th>
                          <th>Start time</th>
                          <th>Finish time</th>
						  <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
						  <select className="form-control">
						  <option>Select Job</option>
						  <option>#JB25 - Customer Name</option>
						  <option>#JB27 - Customer Name</option>
						  </select>	
						  </td>
						  <td>
						  <Input name="firstname" type="text" className="form-control" placeholder="00.00" />
						  </td>
                          <td>
						  <Input name="firstname" type="text" className="form-control" placeholder="00.00" />
						  </td>
                          <td className="text-end">
							<Link to="#" className="btn btn-sm btn-soft-danger">
								<i className="mdi mdi-delete-outline" />								
							</Link>
						  </td>
                        </tr>
                      </tbody>
                    </Table>
					</div>
					<Button color="success" className="btn btn-sm"><i className="bx bx-plus font-size-16 align-middle"></i> Add Time</Button>
					</Col>
					</Row>
					
					
					<Row>
					<Col>
						<NotesAttachments />
					</Col>
					</Row>
					
				</div>	
					
			
    </React.Fragment>
  )
}

export default NewTimesheet;
