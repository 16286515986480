import PropTypes from 'prop-types'
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Card, CardBody, CardTitle, Label, Form, Input, InputGroup, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, 
Table, Badge, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown, UncontrolledDropdown, Nav, NavItem, NavLink, TabContent, TabPane, CardText,
 AccordionBody, AccordionHeader, AccordionItem, Collapse, UncontrolledAccordion, UncontrolledCollapse, Alert} from "reactstrap";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react"
import { connect } from "react-redux"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import EmailBody from "../Shortcut/email-body";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { useFormik } from "formik";
import * as Yup from 'yup';
import classnames from "classnames";
import LightData from "../Schedule/LightData"
const LoadingContainer = () => <div>Loading...</div>
import EquipmentsList from "./equipments-list";

const MapsGoogle = props => {

  document.title="Customer - SYANPRO";
  const [singlebtn, setSinglebtn] = useState(false)	
  const [modal_standard, setmodal_standard] = useState(false);
  const [editQuoteNo, setShow] = useState(false); 
  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  
  const selectedPlace = {}
  function onMarkerClick() {
    alert("You clicked in this marker")
  }  
  const [customchkPrimary, setcustomchkPrimary] = useState(true);
  const [customchkSuccess, setcustomchkSuccess] = useState(true);
  const [customchkInfo, setcustomchkInfo] = useState(true);
  const [customchkWarning, setcustomchkWarning] = useState(true);
  const [customchkDanger, setcustomchkDanger] = useState(true);

  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}         
		  
		  <Row>
			<Col lg="6">
			<Breadcrumbs title="Service" breadcrumbItem="Customer" />
			</Col>
			<Col lg="6">
			<div className="neg-margin">
				<UncontrolledDropdown className="text-end float-end">
				  <DropdownToggle color="primary" outline type="button">
				  <i className="bx bx-dots-horizontal-rounded font-size-16 me-2"></i> 
					More Actions <i className="mdi mdi-chevron-down"></i>
				  </DropdownToggle>
				  <DropdownMenu>
					<div className="dropdown-header noti-title">
					  <h5 className="font-size-13 text-muted text-truncate mn-0">Send And...</h5>
					</div>
					<DropdownItem href="#"><i className="bx bx-wrench font-size-16 me-2"></i> Convert to Job</DropdownItem>
					<DropdownItem href="#"><i className="bx bx-collection font-size-16 me-2"></i> Convert to Project</DropdownItem>
					<DropdownItem href="#"><i className="bx bx-copy-alt font-size-16 me-2"></i> Duplicate</DropdownItem>
					<DropdownItem href="#"><i className="bx bxs-file-pdf font-size-16 me-2"></i> Download PDF</DropdownItem>
					<div className="dropdown-divider"></div>
					<DropdownItem href="#"><i className="bx bx-hourglass font-size-16 me-2"></i> Mark as Awaiting Response</DropdownItem>
					<DropdownItem href="#"><i className="bx bx-like font-size-16 me-2"></i> Accepted</DropdownItem>
					<DropdownItem href="#"><i className="bx bx-dislike font-size-16 me-2"></i> Declined</DropdownItem>
					<div className="dropdown-divider"></div>
					<DropdownItem href="#"><i className="bx bx-show-alt font-size-16 me-2"></i> Preview as Customer</DropdownItem>
					<DropdownItem href="#"><i className="bx bxs-pencil font-size-16 me-2"></i> Collect Signature</DropdownItem>
				  </DropdownMenu>
				</UncontrolledDropdown>
				
				<Button color="success" outline className="btn float-end me-3"><i className="bx bx-edit-alt font-size-16 me-2"></i> Edit</Button>	
				
				<Button color="success" className="btn float-end me-3"
				onClick={(e) => {
				e.preventDefault();
				tog_standard();
				}}
				>
				<i className="bx bx-mail-send font-size-16 me-2"></i> Send Email
				</Button>
				
			</div>
			</Col>
		  </Row>
		  
		  <Modal
			  size="lg"
			  isOpen={modal_standard}
			  toggle={() => {
				tog_standard();
			  }}
			  centered
			>
			  <div>                       
				<button
				  type="button"
				  onClick={() => {
					setmodal_standard(false);
				  }}
				  className="close"
				  data-dismiss="modal"
				  aria-label="Close"
				>
				  <span aria-hidden="true">&times;</span>
				</button>
			  </div>
			  <div className="modal-body">
			   
			   <EmailBody />
			   
			  </div>
			   <div className="modal-footer">
			   <Button color="primary" outline className="btn float-end me-1"
			   onClick={() => {
					tog_standard();
				  }}
			   >
			   Cancel
			   </Button>	
			   <Button color="success" className="btn float-end me-3">Send Email</Button>
				
			  </div>
			</Modal>
		  		  
		    
             <Card className="mainCard">
                <CardBody>
				
				<Row>
				<Col>
					<div className="d-flex">
					<div className="ms-3 me-3">
					
					  <div className="avatar-sm rounded-circle bg-warning align-self-center mini-stat-icon">
						<span className="avatar-title rounded-circle bg-warning font-size-18 fw-bolder">
						  RG
						</span>
					  </div>
					</div>
					<div className="flex-grow-1 align-self-center">
					  <div>
					     <div className="form-check form-switch form-switch-lg float-end">
						  <input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitchsizelg"
                            defaultChecked
                          />
                        </div>
						  <label className="form-check-label float-end mt-1 me-2" htmlFor="customSwitchsizelg">
                            Active
                          </label>
						
						<h4>Relaince General Contracting LLC</h4>
						<p className="mb-0"><span className="fw-bold">CUS104</span> <span className="text-muted ms-2 me-2">|</span> 25001 East Avenue Street, Calicut 673010, Kerala, India</p>
						<p className="mb-0 font-size-12 text-muted">VAT No. 30000158754025 <span className="ms-2 me-2">|</span> Recurring Customer</p>
					  </div>
					</div>
					</div>
				</Col>
				</Row>				
				
				<Row className="mt-4">
				<Col lg={12}>
				<Nav tabs className="nav-tabs-custom nav-justified custom-tab mt-3">
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "1", })} onClick={() => { toggleCustom("1"); }}>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block font-size-14">Sites</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "2", })} onClick={() => { toggleCustom("2"); }}>
                        <span className="d-block d-sm-none">
                          <i className="far fa-user"></i>
                        </span>
                        <span className="d-none d-sm-block font-size-14">Equipments</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "3", })} onClick={() => { toggleCustom("3"); }}>
                        <span className="d-block d-sm-none">
                          <i className="far fa-envelope"></i>
                        </span>
                        <span className="d-none d-sm-block font-size-14">Contacts</span>
                      </NavLink>
                    </NavItem>                   
					<NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "4", })} onClick={() => { toggleCustom("4"); }}>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-cog"></i>
                        </span>
                        <span className="d-none d-sm-block font-size-14">Requests</span>
                      </NavLink>
                    </NavItem>
					<NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "5", })} onClick={() => { toggleCustom("5"); }}>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-cog"></i>
                        </span>
                        <span className="d-none d-sm-block font-size-14">Quotes</span>
                      </NavLink>
                    </NavItem>
					<NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "6", })} onClick={() => { toggleCustom("6"); }}>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-cog"></i>
                        </span>
                        <span className="d-none d-sm-block font-size-14">Jobs</span>
                      </NavLink>
                    </NavItem>
					<NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "7", })} onClick={() => { toggleCustom("7"); }}>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-cog"></i>
                        </span>
                        <span className="d-none d-sm-block font-size-14">Invoices</span>
                      </NavLink>
                    </NavItem>
					 <NavItem>
                      <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "8", })} onClick={() => { toggleCustom("8"); }}>
                        <span className="d-block d-sm-none">
                          <i className="fas fa-cog"></i>
                        </span>
                        <span className="d-none d-sm-block font-size-14">Attachments</span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent
                    activeTab={customActiveTab}
                    className="p-3"
                  >
                <TabPane tabId="1">
                <Row className="map-height mt-2">
				<Col lg={5}>
				
				  <Row className="mb-2">
					<Col>				  
					<h3 className="float-start">Sites</h3>
					<Button color="success" className="btn btn-sm float-end"><i className="bx bx-plus me-1"></i> New Site</Button>		
					</Col>				  
				  </Row>
				  
				<Row>
				<Col>
				  <Label className="float-end fw-normal font-size-12">Show All (2) 
				  <input
					className="form-check-input ms-2"
					type="checkbox"
					value=""
					id="defaultCheck1"
					defaultChecked
				  />
				  </Label>
				</Col>
				</Row>
				  
				  <Alert color="secondary mb-3 bgWhite padding-a">
				  <input
					className="form-check-input float-end"
					type="checkbox"
					value=""
					id="defaultCheck1"
					defaultChecked
				  />						  
				  <Link className="float-end font-size-15 me-2"><i className="bx bx-edit-alt"></i></Link>
				  <p className="mb-1 fw-bold">Dubai Branch Office</p> 
				  <div className="text-muted font-size-12">
				  1300 Constitution Ave NW, Washington, DC..
				  </div>
				  </Alert>
				  
				   <Alert color="secondary mb-0 bgWhite padding-a">
				  <input
					className="form-check-input float-end"
					type="checkbox"
					value=""
					id="defaultCheck1"
					defaultChecked
				  />
				  <Link className="float-end font-size-15 me-2 d-none"><i className="bx bx-edit-alt"></i></Link>
				  <p className="mb-1 fw-bold">Relaince General Contracting LLC</p> 
				  <div className="text-muted font-size-12 text-truncate">
				  25001 East Avenue Street, Calicut 673010, Kerala, India
				  </div>
				  <Badge color="success" className="font-size-10 fw-normal mt-1">Primary</Badge>
				  </Alert>
						  
				</Col>
				<Col lg={7}> 
				<div
                    id="gmaps-markers"
                    className="gmaps"
                    style={{ position: "relative" }}
                  >
                    <Map
                      google={props.google}
                      style={{ width: "100%", height: "500px" }}
                      zoom={14}
                    >
                      <Marker
                        title={"The marker`s title will appear as a tooltip."}
                        name={"SOMA"}
                        position={{ lat: 37.778519, lng: -122.40564 }}
                      />
                      <Marker name={"Dolores park"} />
                      <InfoWindow>
                        <div>
                          <h1>{selectedPlace.name}</h1>
                        </div>
                      </InfoWindow>
                    </Map>
                  </div>
				</Col>				
				</Row>  
                    </TabPane>
                    <TabPane tabId="2">
                      <Row className="mt-2 mb-3">
                        <Col sm="10">
                          <h3>Equipments</h3>
                        </Col>
						<Col sm="2 text-end">                          
                        </Col>
                      </Row>
					  <Row>
					  <Col>
					  <EquipmentsList />
					  </Col>
					  </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row className="mt-2 mb-3">
                        <Col sm="10">
                          <h3>Contacts</h3>
                        </Col>
						<Col sm="2 text-end">                          
                        </Col>
                      </Row>
					  <Row>
					  <Col>
					  <EquipmentsList />
					  </Col>
					  </Row>
                    </TabPane>
					<TabPane tabId="4">
                      <Row className="mt-2 mb-3">
                        <Col sm="10">
                          <h3>Requests</h3>
                        </Col>
						<Col sm="2 text-end">                          
                        </Col>
                      </Row>
					  <Row>
					  <Col>
					  <EquipmentsList />
					  </Col>
					  </Row>
                    </TabPane>
					<TabPane tabId="5">
                      <Row className="mt-2 mb-3">
                        <Col sm="10">
                          <h3>Quotes</h3>
                        </Col>
						<Col sm="2 text-end">                          
                        </Col>
                      </Row>
					  <Row>
					  <Col>
					  <EquipmentsList />
					  </Col>
					  </Row>
                    </TabPane>
					<TabPane tabId="6">
                      <Row className="mt-2 mb-3">
                        <Col sm="10">
                          <h3>Jobs</h3>
                        </Col>
						<Col sm="2 text-end">                          
                        </Col>
                      </Row>
					  <Row>
					  <Col>
					  <EquipmentsList />
					  </Col>
					  </Row>
                    </TabPane>
					<TabPane tabId="7">
                      <Row className="mt-2 mb-3">
                        <Col sm="10">
                          <h3>Invoices</h3>
                        </Col>
						<Col sm="2 text-end">                          
                        </Col>
                      </Row>
					  <Row>
					  <Col>
					  <EquipmentsList />
					  </Col>
					  </Row>
                    </TabPane>					
                    <TabPane tabId="8">
                      <Row className="mt-2 mb-3">
                        <Col sm="10">
                          <h3>Attachments</h3>
                        </Col>
						<Col sm="2 text-end">                          
                        </Col>
                      </Row>
					  <Row>
					  <Col>
					  <EquipmentsList />
					  </Col>
					  </Row>
                    </TabPane>
                  </TabContent>
				</Col>
				</Row>
				
				</CardBody>
			 </Card>
            
        </Container>
      </div>
    </React.Fragment>
  )
}

MapsGoogle.propTypes = {
  google: PropTypes.object
}

export default connect(
  null,
  {}
)(
  GoogleApiWrapper({
    apiKey: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    LoadingContainer: LoadingContainer,
    v: "3",
  })(MapsGoogle)
)
