import React from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

const Breadcrumb = props => {
  return (
    <Row>
      <Col xs={12}>
        <div className="page-title-box d-sm-flex">
          <span className="me-2">Mindbridge | </span> <h4 className="mb-sm-0 font-size-18">{props.breadcrumbItem}</h4>          
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string
}

export default Breadcrumb
