import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Card, CardBody, CardTitle, Label, Form, Input, InputGroup, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, 
Table, Badge, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown, Offcanvas, OffcanvasHeader, OffcanvasBody} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from 'yup';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import ProductListing from "./products-listing";
import NewProduct from "./new-product";
import Filter from "../../Shortcut/filter";


const Products = () => {

  const [singlebtn, setSinglebtn] = useState(false)	
  document.title="Jobs - SYANPRO";
  const [isRight, setIsRight] = useState(false);
  const toggleRightCanvas = () => {
	setIsRight(!isRight);
  };
  
  const [modal_standard, setmodal_standard] = useState(false);
  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}         
		  
		  <Row>
			<Col lg="6">
			<Breadcrumbs title="Services" breadcrumbItem="Products & Services" />
			</Col>
			<Col lg="6" className="neg-margin">
				<div className="d-flex gap-2 float-end">
				<Button color="primary" outline className="btn me-2" onClick={toggleRightCanvas}><i className="bx bx-filter-alt font-size-16 align-middle me-2"></i> Filter</Button>				
				
				<Button color="success" className="btn me-2"
				onClick={(e) => {
				e.preventDefault();
				tog_standard();
				}}
				><i className="bx bx-plus font-size-16 align-middle me-2"></i> New Product</Button>
				<Dropdown
				 isOpen={singlebtn}
				 toggle={() => setSinglebtn(!singlebtn)}
				 className="mt-4 mt-sm-0"
				>
				<DropdownToggle className="btn btn-primary" caret>
				  <i className="bx bx-dots-horizontal-rounded font-size-16 align-middle me-2"></i>
				  More Actions <i className="mdi mdi-chevron-down" />
				</DropdownToggle>
				<DropdownMenu>
				  <DropdownItem>Quotes Report</DropdownItem>
				  <DropdownItem>Quote Settings</DropdownItem>
				  <DropdownItem>Email Template</DropdownItem>
				</DropdownMenu>
				</Dropdown>	
				</div>
			</Col>
		  </Row>
		  
		   <Modal
			  size="lg"
			  isOpen={modal_standard}
			  toggle={() => {
				tog_standard();
			  }}
			  centered
			>
			  <div>                       
				<button
				  type="button"
				  onClick={() => {
					setmodal_standard(false);
				  }}
				  className="close"
				  data-dismiss="modal"
				  aria-label="Close"
				>
				  <span aria-hidden="true">&times;</span>
				</button>
			  </div>
			  <div className="modal-body">
			   
			  <NewProduct />
			   
			  </div>
			   <div className="modal-footer">
			   <Button color="primary" outline className="btn float-end me-1"
			   onClick={() => {
					tog_standard();
				  }}
			   >
			   Cancel
			   </Button>			   
			   <Button outline color="success" className="btn float-end me-1">Save and New</Button>
			   <Button color="success" className="btn float-end me-3">Save and Close</Button>
				
			  </div>
			</Modal>
		  		  
		   <Row>	
            <Col lg={12}> 			  
              <ProductListing />
            </Col>			
          </Row>
        </Container>
      </div>
	  
	  
	  <Offcanvas
		isOpen={isRight}
		direction="end"
		toggle={toggleRightCanvas}>
		<OffcanvasHeader toggle={toggleRightCanvas}>
			Filters
		</OffcanvasHeader>
		<OffcanvasBody>
			<Filter />
		</OffcanvasBody>
	  </Offcanvas>
	  
    </React.Fragment>
  )
}

export default Products;
