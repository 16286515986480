import React, { useState } from "react";
import { Container, Row, Col, Button, Card, CardBody, CardTitle, Label, Form, Input, InputGroup, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, Table, Badge, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown, UncontrolledDropdown} from "reactstrap";
import { Link } from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import NewCustomer from "../Shortcut/create-customer";

const CustomerShortcut = () => {  
  
  
  return (
    <React.Fragment>
	
					<div id="customerList">
					
                        <h4>Select or create a customer</h4>
                        <p>
                          Which customer would you like to create this for?
                        </p>
						
						<Row className="mt-3">
							<Col sm={12}>
								<div className="border rounded-3 padding-a">
								
								<Row>
									<Col sm={7}>
									<Input
									  name="firstname"
									  type="text"
									  className="form-control"
									  id="horizontal-firstname-Input"
									  placeholder="Search customers..."
									/>		
									</Col>
									<Col sm={5}>
									<p className="float-start pt-2">or</p>
									<Button color="primary" className="btn float-end">Create New Customer</Button>
									</Col>
								</Row>
								
								<div className="border bg-light mt-2 mb-4"></div>		
								
								<Row className="LinkList">
								 <Col sm={1} className="text-center">
									<i className="bx bx-crown font-size-16"></i>								
								 </Col>
								 <Col sm={11}>
									<p className="fw-bold">Mindbridge Business Solutions Pvt Ltd (مايندبريدج لحلول الأعمال المحدودة)</p>									
								 </Col>
								</Row>
								
								</div>
							</Col>
						</Row>
						
						</div>
						
						<div id="newCustomer">						
							<NewCustomer />						
						</div>
                      
					
						<div className="text-end mb-3 mt-3">
						<Button color="primary" outline className="btn">Cancel</Button>
						<Button color="success" className="btn float-end ms-3 me-3">Create Customer</Button>						
						</div>					
					
                 
	 
    </React.Fragment>
  )
}

export default CustomerShortcut;
