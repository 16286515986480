import React, { useState } from "react";
import { Container, Row, Col, Button, Card, CardBody, CardTitle, Label, Form, Input, InputGroup, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, Table, Badge, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown, UncontrolledDropdown} from "reactstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup';
import EmailBodySide from "../Shortcut/email-body-side";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const CustomerShortcut = () => {  
  
  
  const floatingformik = useFormik({
    initialValues: {
      name: "",
      email: "",
      select: "",
      check: ""
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required"),
      email: Yup.string().email().matches(/^(?!.*@[^,]*,)/).required("Please Enter Your Email"),
      select: Yup.string().required("This field is required"),
      check: Yup.string().required("This field is required"),
    }),

    onSubmit: (values) => {
      // console.log("value", values.password);
    },
  });
  
  return (
    <React.Fragment>
					
						
						
		<h4 className="mb-4 mt-3">Email Quote #4 to Vipin</h4>
			   
			   <Row>
			     <Col sm={7}>
				 
					<Row>
                    <Col xs={9} className="me-0 pe-0">
                      <div>
						<InputGroup className="mb-3">
						  <Label className="input-group-text">To</Label>						  
						  <Input type="text" className="form-control" value="vipin.p@google.com" />
						</InputGroup>
					</div>			  					  
                    </Col>
					<Col xs={2} className="ms-0 ps-0">					
					<UncontrolledDropdown className="text-end float-end">
					  <DropdownToggle color="success" outline type="button" className="p-tb-5">
					  <i className="bx bx-dots-horizontal-rounded font-size-16 mt-1"></i> 
					  </DropdownToggle>
					  <DropdownMenu>
						<DropdownItem href="#"><i className="bx bx-add-to-queue font-size-16 me-2"></i> Add CC</DropdownItem>
						<DropdownItem href="#"><i className="bx bx-customize font-size-16 me-2"></i> Customize Template</DropdownItem>
					  </DropdownMenu>
					</UncontrolledDropdown>
					</Col>
					</Row>
					
					<Row>
					<Col xs={12}>
					   <div className="form-floating mb-3">
                      <input type="text" name="name" className="form-control" id="floatingnameInput" placeholder="Subject"
                        value="Quote from Mindbridge - Mar 19, 2024"
                        onChange={floatingformik.handleChange}
                        onBlur={floatingformik.handleBlur}
                      />
                      <label htmlFor="floatingnameInput">Subject</label>
                      {
                        floatingformik.errors.name && floatingformik.touched.name ? (
                          <span className="text-danger">{floatingformik.errors.name}</span>
                        ) : null
                      }
					   <Input
						type="textarea"
						id="formmessage"
						name="msg"
						className="form-control mb-3 border-top-0 min-h-250"
						rows="10"
						placeholder="Enter your Message"
						value="
						Hi Vipinz,
						
						Thank you for asking us to quote on your project.
						
						The quote total is SR5,000.00 as of Mar 09, 2024.
						
						If you have any questions or concerns regarding this quote, please don't hesitate to get in touch with us at fazal@mindbridge.in.
						
						Sincerely,
							
						Mindbridge
						"							
					  />
                    </div>
					</Col>
					</Row>
                  
				 <div className="form-check">
				  <input
					className="form-check-input"
					type="checkbox"
					value=""
					id="defaultCheck1"
				  />
				  <label
					className="form-check-label"
					htmlFor="defaultCheck1"
				  >
					Send me a copy
				  </label>
				</div>
				 
				 
			     </Col>
			     <Col sm={5}>
				 
				 <EmailBodySide />
				 
			     </Col>
			   </Row>
						
                      
                 
	 
    </React.Fragment>
  )
}

export default CustomerShortcut;
