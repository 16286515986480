import React from "react";
import { Card, Col, Container, Row, CardBody, CardTitle, Label, Button, Form, Input, InputGroup, FormFeedback, Table } from "reactstrap";
import { Link } from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const GridTotal = () => {

  return (
    <React.Fragment>
	
   <div className="table-responsive">
	<Table className="align-middle mb-0">	  
	  <tbody>
		<tr>
		  <td width="35%">Subtotal</td>
		  <td width="40%"></td>
		  <th width="25%" className="text-end">SR 0.00</th>
		</tr>
		<tr>
		  <td>Discount</td>
		  <td>
		  <Row>
		  <Col sm={6} className="me-0 pe-0">
		  <Input
			  name="firstname"
			  type="text"
			  className="form-control"
			  id="horizontal-firstname-Input"
			  placeholder="0.00"
			/>	
		  </Col>
		  <Col sm={6} className="ms-0 ps-0">
			  <select className="form-select col-sm-5 form-control-sm" name="Discount">
				<option defaultValue="0">SAR</option>
				<option value="1">%</option>
			   </select>
		  </Col>
		</Row>		  
		  </td>
		  <th className="text-end">SR -0.00</th>
		</tr>
		<tr>
		  <td>Tax</td>
		  <td>
		   <select className="form-select form-control-sm" name="TAX">
			<option defaultValue="0">Select Tax Type</option>
			<option value="1">One</option>
			<option value="2">Two</option>
			<option value="3">Three</option>
		   </select>           
		  </td>
		  <th className="text-end">SR 0.00</th>
		</tr>
		<tr>
		  <th className="text-success">Total</th>
		  <td></td>
		  <th className="text-end text-success">SR 0.00</th>
		</tr>
		<tr>
		  <td className="border-bottom-0">Required deposit</td>
		  <td className="border-bottom-0"></td>
		  <th className="border-bottom-0 text-end"><Link>Add required deposit</Link></th>
		</tr>
	  </tbody>
	</Table>
  </div>
	 
    </React.Fragment>
  )
}

export default GridTotal;
