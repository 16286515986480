import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Card, CardBody, CardTitle, Label, Form, Input, InputGroup, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, Table, Badge, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown, UncontrolledDropdown} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import CustomerSelection from "../../Shortcut/customer-selection";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

const NewJob = () => {

  document.title="New Job - SYANPRO";
  const [singlebtn, setSinglebtn] = useState(false)	
  const [modal_standard, setmodal_standard] = useState(false);
  const [editQuoteNo, setShow] = useState(false);
  
  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }
    function tog_fullscreen() {
    setmodal_fullscreen(!modal_fullscreen);
    removeBodyCss();
  }

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  return (
    <React.Fragment>                      
		  
				 <div className="padding-b">
					
					<Row className="mb-4">
						<h4>Job for 
						<Link 
						className="me-1 ms-1 text-body b-b-d"
						onClick={(e) => {
						e.preventDefault();
						tog_standard();
						}}>
					  Customer Name
					  </Link> 
					  <Button color="warning" className="btn-sm"><i className="bx bx-plus font-size-16 align-middle"></i></Button></h4>
					</Row>
						
					<Modal
                      isOpen={modal_standard}
                      toggle={() => {
                        tog_standard();
                      }}
                    >
                      <div>                       
                        <button
                          type="button"
                          onClick={() => {
                            setmodal_standard(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                       
					   <CustomerSelection />
					   
                      </div>
                    </Modal>
					
					<Row className="mb-3">
					<Col sm={5}>
					<div><Label>Priority</Label></div>						
					<div className="btn-group" role="group">
					  <input type="radio" className="btn-check" name="btnradio" id="btncheck1" autoComplete="off" defaultChecked />
					  <label className="btn btn-outline-secondary mb-0" htmlFor="btncheck1">None</label>

					  <input type="radio" className="btn-check" name="btnradio" id="btncheck2" autoComplete="off" />
					  <label className="btn btn-outline-warning mb-0" htmlFor="btncheck2">Low</label>

					  <input type="radio" className="btn-check" name="btnradio" id="btncheck3" autoComplete="off" />
					  <label className="btn btn-outline-success mb-0" htmlFor="btncheck3">Medium</label>
					  
					  <input type="radio" className="btn-check" name="btnradio" id="btncheck4" autoComplete="off" />
					  <label className="btn btn-outline-danger mb-0" htmlFor="btncheck4">High</label>
					</div>	
					</Col>					
					<Col sm={6}>
						<Label>Job No. #4</Label>
						<div className="row">
						{ editQuoteNo &&
						<div className="col-sm-7 me-1 ms-0 pe-0">
						<Input
						  name="firstname"
						  type="text"
						  className="form-control"
						  id="horizontal-firstname-Input"
						  placeholder="New Number"
						/>	
						</div>
						}
						<div className="col-sm-4 ms-0">
						<Button color="primary" outline className="btn" onClick={()=>setShow(!editQuoteNo)}>{editQuoteNo === true ? 'Update' : 'Change'}</Button>
						</div>
						</div>
					</Col>
					</Row>
					
					
					<Row className="mb-4">
                      <Col lg={7}>
                       <Label htmlFor="formrow-InputState">Site</Label>
					   <div className="row">
						<div className="col-sm-10">
						<select
						name="state"
						id="formrow-InputState"
						className="form-control"                            
					    >
						<option >Select Site</option>
						<option>...</option>
					    </select>
						</div>
						<div className="col-sm-2 ps-0 ms-0">
						<Button color="warning" className="btn"><i className="bx bx-plus font-size-16 align-middle"></i></Button>
						</div>
					   </div>
                     </Col>

                      <Col lg={5}>                       
					  <Label>Quote No.</Label>
					<select className="form-control">
					  <option>Select Quote</option>
					  <option>QT524 - Mar 05, 2024</option>
					  <option>QT523 - Mar 03, 2024</option>
					</select>
                      </Col>
                    </Row>
					
					
					 <span className="placeholder col-12 placeholder-xs bg-light mb-4"></span>	
					 
						
						<Row className="mb-1">
						  <Label htmlFor="formrow-InputCity">Job Title</Label>
						  <Col sm={6}>
							<Input
							  name="firstname"
							  type="text"
							  className="form-control"
							  id="horizontal-firstname-Input"
							  placeholder="Job Title"
							/>								
						  </Col>
						  <Col sm={6}>
							<Input
							  name="firstname"
							  type="text"
							  className="form-control text-end ar-type"
							  id="horizontal-firstname-Input"
							  placeholder="مسمى وظيفي"
							/>								
						  </Col>
						</Row>	
						
						<Row className="mb-3">
						<Col>
						<textarea
						  id="message"
						  rows="4"
						  className="form-control rounded-bottom"
						  placeholder="Description"
						></textarea>
						</Col>
						</Row>

					<Row>
					<Col lg={4}>
					<Label htmlFor="formrow-InputState">Category</Label>
					  <select
						name="state"
						id="formrow-InputState"
						className="form-control"                            
					  >
						<option >Select Site</option>
						<option>...</option>
					  </select> 
					</Col>
					<Col lg={4}>
					<Label htmlFor="formrow-InputState">Service Contract</Label>
                          <select
                            name="state"
                            id="formrow-InputState"
                            className="form-control"                            
                          >
                            <option >Responsible</option>
                            <option>...</option>
                          </select>
					</Col>
					<Col lg={4}>
					<Label>Reference</Label>
					<Input
						type="text"
						name="zip"
						className="form-control"
						id="formrow-InputZip"
						placeholder=""                           
					  />  
					</Col>
					</Row>
					
					
					
				</div>	
					
			
    </React.Fragment>
  )
}

export default NewJob;
