import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Card, CardBody, CardTitle, Label, Form, Input, InputGroup, FormFeedback, 
Table, Badge, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, 
ButtonDropdown, UncontrolledDropdown, CardText, CardHeader} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import NotesAttachments from "../Shortcut/notes-attachments";
import NewRequestBodyA from "./new-request-body";
import NewRequestTopRight from "./new-request-top-right";
import NewRequestTopLeft from "./new-request-top-left";

const NewJob = () => {

  document.title="New Request - SYANPRO";
  const [singlebtn, setSinglebtn] = useState(false)	 

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>       
		  
		  <Row>
			<Col lg="10">
			<Breadcrumbs title="Services" breadcrumbItem="New Request" />
			</Col>
			<Col lg="2">
				
			</Col>
		  </Row>
		  		  
		   <Row>
            <Col lg="12">
             <Card className="mainCard">
                <CardBody className="card-body-a">				
				
				 <div className="row mb-4">
				  <div className="col-sm-8">
					<NewRequestTopLeft />
				  </div>
				  <div className="col-sm-4">
				    <div className="ps-5">
					 <NewRequestTopRight />											  
					</div>
				  </div>
				</div>  
				  
				<span className="placeholder col-12 placeholder-xs bg-light mb-4"></span>	
				  
				<NewRequestBodyA />	
					
				<Row>
				<Col>
					<NotesAttachments />
				</Col>
				</Row>
					
				<Row>
				<Col lg={2}>
					<Button color="primary" outline className="btn">Cancel</Button>
				</Col>
				<Col lg={10} className="text-end">		
					
				<UncontrolledDropdown className="text-end float-end">
				  <DropdownToggle color="success" type="button">
					Save And... <i className="mdi mdi-chevron-down"></i>
				  </DropdownToggle>
				  <DropdownMenu>
					<div className="dropdown-header noti-title">
					  <h5 className="font-size-13 text-muted text-truncate mn-0">Send And...</h5>
					</div>
					<DropdownItem href="#"><i className="bx bx-mail-send font-size-16 me-2"></i> Send as Email</DropdownItem>
					<DropdownItem href="#"><i className="bx bx-wrench font-size-16 me-2"></i> Convert to Job</DropdownItem>
					<DropdownItem href="#"><i className="bx bxs-file-pdf font-size-16 me-2"></i> Save and Download PDF</DropdownItem>
					<div className="dropdown-divider"></div>
					<DropdownItem href="#"><i className="bx bx-hourglass font-size-16 me-2"></i> Mark as Awaiting Response</DropdownItem>
				  </DropdownMenu>
				</UncontrolledDropdown>
				
					<Link to="/job-detail"><Button color="success" outline className="btn float-end me-3">Save Job</Button></Link>						
				</Col>					
				</Row>
					
					
				</CardBody>
			 </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default NewJob;
