import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Card, CardBody, CardTitle, Label, Form, Input, InputGroup, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, 
Table, Badge, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown, Offcanvas, OffcanvasHeader, OffcanvasBody, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from 'yup';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import classnames from "classnames";
import CompanySettings from "./company-settings";

const Settings = () => {

  const [singlebtn, setSinglebtn] = useState(false)	
  document.title="Settings - SYANPRO";
  const [verticalActiveTab, setverticalActiveTab] = useState("1");
  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="bgWhite mainCard">
		  		  
		   <Row>	
            <Col lg={3}> 	
			 <Card className="mb-0">
                <CardBody>
              <Nav pills className="flex-column">
				<NavItem>
				  <p className="fw-bold mb-2 mt-0">BUSINESS MANAGEMENT</p>
				</NavItem>
				
				<NavItem>
				  <NavLink style={{ cursor: "pointer" }} className={classnames({ "mb-2": true, active: verticalActiveTab === "1", })} onClick={() => { toggleVertical("1"); }}>
					Company Settings
				  </NavLink>
				</NavItem>
				<NavItem>
				  <NavLink style={{ cursor: "pointer" }} className={classnames({ "mb-2": true, active: verticalActiveTab === "2", })} onClick={() => { toggleVertical("2"); }}>
					Branding
				  </NavLink>
				</NavItem>
				<NavItem>
				  <NavLink style={{ cursor: "pointer" }} className={classnames({ "mb-2": true, active: verticalActiveTab === "3", })} onClick={() => { toggleVertical("3"); }}>
					Products & Services
				  </NavLink>
				</NavItem>
				<NavItem>
				  <NavLink style={{ cursor: "pointer" }} className={classnames({ "mb-2": true, active: verticalActiveTab === "4", })} onClick={() => { toggleVertical("4"); }}>
					Custom Fields
				  </NavLink>
				</NavItem>
				<NavItem>
				  <NavLink style={{ cursor: "pointer" }} className={classnames({ "mb-2": true, active: verticalActiveTab === "5", })} onClick={() => { toggleVertical("5"); }}>
					Expense Tracking
				  </NavLink>
				</NavItem>
				
				<NavItem>
				  <p className="fw-bold mb-2 mt-2">TEAM ORGANIZATION</p>
				</NavItem>
				
				<NavItem>
				  <NavLink style={{ cursor: "pointer" }} className={classnames({ "mb-2": true, active: verticalActiveTab === "6", })} onClick={() => { toggleVertical("6"); }}>
					Manage Team
				  </NavLink>
				</NavItem>
				<NavItem>
				  <NavLink style={{ cursor: "pointer" }} className={classnames({ "mb-2": true, active: verticalActiveTab === "7", })} onClick={() => { toggleVertical("7"); }}>
					Work Settings
				  </NavLink>
				</NavItem>
				<NavItem>
				  <NavLink style={{ cursor: "pointer" }} className={classnames({ "mb-2": true, active: verticalActiveTab === "8", })} onClick={() => { toggleVertical("8"); }}>
					Schedule
				  </NavLink>
				</NavItem>
				<NavItem>
				  <NavLink style={{ cursor: "pointer" }} className={classnames({ "mb-2": true, active: verticalActiveTab === "9", })} onClick={() => { toggleVertical("9"); }}>
					Location Services
				  </NavLink>
				</NavItem>
				<NavItem>
				  <NavLink style={{ cursor: "pointer" }} className={classnames({ "mb-2": true, active: verticalActiveTab === "10", })} onClick={() => { toggleVertical("10"); }}>
					Route Optimization
				  </NavLink>
				</NavItem>
				<NavItem>
				  <NavLink style={{ cursor: "pointer" }} className={classnames({ "mb-2": true, active: verticalActiveTab === "11", })} onClick={() => { toggleVertical("11"); }}>
					Job Forms
				  </NavLink>
				</NavItem>
				
				<NavItem>
				  <p className="fw-bold mb-2 mt-2">CLIENT COMMUNICATION</p>
				</NavItem>
				
				<NavItem>
				  <NavLink style={{ cursor: "pointer" }} className={classnames({ "mb-2": true, active: verticalActiveTab === "12", })} onClick={() => { toggleVertical("12"); }}>
					Customer Portal
				  </NavLink>
				</NavItem>
				<NavItem>
				  <NavLink style={{ cursor: "pointer" }} className={classnames({ "mb-2": true, active: verticalActiveTab === "13", })} onClick={() => { toggleVertical("13"); }}>
					Emails
				  </NavLink>
				</NavItem>
				<NavItem>
				  <NavLink style={{ cursor: "pointer" }} className={classnames({ "mb-2": true, active: verticalActiveTab === "14", })} onClick={() => { toggleVertical("14"); }}>
					Request
				  </NavLink>
				</NavItem>
				
				<NavItem>
				  <p className="fw-bold mb-2 mt-2">CONNECTED APPS</p>
				</NavItem>
				
				<NavItem>
				  <NavLink style={{ cursor: "pointer" }} className={classnames({ active: verticalActiveTab === "4", })} onClick={() => { toggleVertical("4"); }}>
					Payment Integration
				  </NavLink>
				</NavItem>
				<NavItem>
				  <NavLink style={{ cursor: "pointer" }} className={classnames({ active: verticalActiveTab === "4", })} onClick={() => { toggleVertical("4"); }}>
					Accounting
				  </NavLink>
				</NavItem>
				<NavItem>
				  <NavLink style={{ cursor: "pointer" }} className={classnames({ active: verticalActiveTab === "4", })} onClick={() => { toggleVertical("4"); }}>
					CRM
				  </NavLink>
				</NavItem>
			  </Nav>
			  </CardBody>
			  </Card>
            </Col>	
			<Col lg={9}>
              <TabContent
				activeTab={verticalActiveTab}
				className="text-muted mt-4 mt-md-0 padding-b"
			  >
				<TabPane tabId="1">
				  <CompanySettings />
				</TabPane>
				<TabPane tabId="2">
				  <p>
					Food truck fixie locavore, accusamus mcsweeney&apos;s
					marfa nulla single-origin coffee squid. Exercitation
					+1 labore velit, blog sartorial PBR leggings next
					level wes anderson artisan four loko farm-to-table
					craft beer twee. Qui photo booth letterpress,
					commodo enim craft beer mlkshk.
				  </p>
				  <p className="mb-0">
					{" "}
					Qui photo booth letterpress, commodo enim craft beer
					mlkshk aliquip jean shorts ullamco ad vinyl cillum
					PBR. Homo nostrud organic, assumenda labore
					aesthetic magna 8-bit
				  </p>
				</TabPane>
				<TabPane tabId="3">
				  <p>
					Etsy mixtape wayfarers, ethical wes anderson tofu
					before they sold out mcsweeney&apos;s organic lomo retro
					fanny pack lo-fi farm-to-table readymade. Messenger
					bag gentrify pitchfork tattooed craft beer, iphone
					skateboard locavore carles etsy salvia banksy hoodie
					helvetica. DIY synth PBR banksy irony. Leggings
					gentrify squid 8-bit cred.
				  </p>
				  <p className="mb-0">
					DIY synth PBR banksy irony. Leggings gentrify squid
					8-bit cred pitchfork. Williamsburg banh mi whatever
					gluten-free.
				  </p>
				</TabPane>

				<TabPane tabId="4">
				  <p>
					Trust fund seitan letterpress, keytar raw denim
					keffiyeh etsy art party before they sold out master
					cleanse gluten-free squid scenester freegan cosby
					sweater. Fanny pack portland seitan DIY, art party
					locavore wolf cliche high life echo park Austin.
					Cred vinyl keffiyeh DIY salvia PBR, banh mi before
					they sold out farm-to-table.
				  </p>
				  <p className="mb-0">
					Fanny pack portland seitan DIY, art party locavore
					wolf cliche high life echo park Austin. Cred vinyl
					keffiyeh DIY salvia PBR, banh mi before they sold
					out farm-to-table.
				  </p>
				</TabPane>
			  </TabContent>
            </Col>			
          </Row>
        </Container>
      </div>
	  
	  
	 
	  
    </React.Fragment>
  )
}

export default Settings;
