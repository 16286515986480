import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Card, CardBody, CardTitle, Label, Form, Input, InputGroup, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, Table, Badge, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown, UncontrolledDropdown} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from 'yup';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import NotesAttachments from "../../Shortcut/notes-attachments";

const NewJob = () => {

  document.title="New Expense - SYANPRO";
  const [singlebtn, setSinglebtn] = useState(false);
  const [editQuoteNo, setShow] = useState(false);
  
  //Floating labels forms
  const floatingformik = useFormik({
    initialValues: {
      name: "",
      email: "",
      select: "",
      check: ""
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required"),
      email: Yup.string().email().matches(/^(?!.*@[^,]*,)/).required("Please Enter Your Email"),
      select: Yup.string().required("This field is required"),
      check: Yup.string().required("This field is required"),
    }),

    onSubmit: (values) => {
      // console.log("value", values.password);
    },
  });
  
    //Horizontal form 
  const horizontalformik = useFormik({
    initialValues: {
      firstname: "",
      email: "",
      password: "",
      check: ""
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("This field is required"),
      email: Yup.string().email().matches(/^(?!.*@[^,]*,)/).required("Please Enter Your Email"),
      password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .matches(RegExp('(.*[a-z].*)'), 'At least lowercase letter')
        .matches(RegExp('(.*[A-Z].*)'), 'At least uppercase letter')
        .matches(RegExp('(.*[0-9].*)'), 'At least one number')
        .required("This field is required"),
      check: Yup.string().required("This field is required"),
    }),

    onSubmit: (values) => {
      // console.log("value", values.password);
    },
  });
 

  return (
    <React.Fragment>                      
		  
				 <div className="padding-b">
					<Row className="mb-3">
					<Col><h3 className="text-body">Add New Product/Service</h3></Col>
					</Row>
					<Row className="mb-3">										
					<Col sm={6}>
						<Label>Code #PR04</Label>
						<div className="row">
						{ editQuoteNo &&
						<div className="col-sm-7 me-1 ms-0 pe-0">
						<Input
						  name="firstname"
						  type="text"
						  className="form-control"
						  id="horizontal-firstname-Input"
						  placeholder="New Number"
						/>	
						</div>
						}
						<div className="col-sm-4 ms-0">
						<Button color="primary" outline className="btn" onClick={()=>setShow(!editQuoteNo)}>{editQuoteNo === true ? 'Update' : 'Change'}</Button>
						</div>
						</div>
					</Col>
					</Row>
					
					<Row>
					<Col md={6}>
                        <div className="form-floating mb-3">
                          <select className="form-select" name="select"
                            value={floatingformik.values.select}
                            onChange={floatingformik.handleChange}
                            onBlur={floatingformik.handleBlur}>
                            <option defaultValue="0">Select Item Type</option>
                            <option value="1">Product</option>
                            <option value="2">Service</option>
                          </select>
                          <label htmlFor="floatingSelectGrid">Item Type</label>
                          <div>
                            {
                              floatingformik.errors.select && floatingformik.touched.select ? (
                                <span className="text-danger">{floatingformik.errors.select}</span>
                              ) : null
                            }
                          </div>
                        </div>
                      </Col>
					</Row>									
						
					<Row className="mb-1">
					  <Label htmlFor="formrow-InputCity">Item Name</Label>
					  <Col sm={6}>
						<Input
						  name="firstname"
						  type="text"
						  className="form-control"
						  id="horizontal-firstname-Input"
						  placeholder="Item Name"
						/>								
					  </Col>
					  <Col sm={6}>
						<Input
						  name="firstname"
						  type="text"
						  className="form-control text-end ar-type"
						  id="horizontal-firstname-Input"
						  placeholder="مسمى وظيفي"
						/>								
					  </Col>
					</Row>	
						
					<Row className="mb-3">
					<Col>
					<textarea
					  id="message"
					  rows="4"
					  className="form-control rounded-bottom"
					  placeholder="Description"
					></textarea>
					</Col>
					</Row>
					
					<Row>
					<Col lg={3} className="me-0 pe-0">
						<div className="form-floating mb-3">
						  <input type="text" name="name" className="form-control group-input-a" id="companycr" placeholder="Enter Name" value="0.00" />
						  <label htmlFor="companycr">Cost (SR)</label>						 
						</div>
					</Col>
					<Col lg={3} className="ms-0 me-0 ps-0 pe-0">
						<div className="form-floating mb-3">
						  <input type="text" name="name" className="form-control group-input-ab" id="companycr" placeholder="Enter Name" value="0" />
						  <label htmlFor="companycr">Margin (%)</label>						 
						</div>
					</Col>
					<Col lg={3} className="ms-0 me-0 ps-0 pe-0">
						<div className="form-floating mb-3">
						  <input type="text" name="name" className="form-control group-input-ab" id="companycr" placeholder="Enter Name" value="0.00" />
						  <label htmlFor="companycr">Unit Price (SR)</label>						 
						</div>
					</Col>
					<Col lg={3} className="ms-0 ps-0">
						<div className="form-floating mb-3">
						  <input type="text" name="name" className="form-control group-input-b" id="companycr" placeholder="Enter Name" value="0" />
						  <label htmlFor="companycr">Available Quantity</label>						 
						</div>
					</Col>
					</Row>
					
					
					<Row className="mb-4">
                      <Col lg={6}>                       
					  <Label>Category</Label>
						<select className="form-control">
						  <option>Select Category</option>
						  <option>JOB524 - Mar 05, 2024</option>
						  <option>JOB523 - Mar 03, 2024</option>
						</select>
                      </Col>
                      <Col lg={6}>                       
					  <Label>Default Tax</Label>
						<select className="form-control">
						  <option>Select Tax</option>
						  <option>Standard (15%)</option>
						  <option>Out of scope</option>
						  <option>Tax excempted</option>
						</select>
                      </Col>
                    </Row>
					
					<Row>
					<Col>
						<NotesAttachments />
					</Col>
					</Row>
					
				</div>	
					
			
    </React.Fragment>
  )
}

export default NewJob;
