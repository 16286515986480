import React, { useState } from "react";
import { Card, Col, Container, Row, CardBody, CardTitle, Label, Button, Form, Input, InputGroup, FormFeedback } from "reactstrap";
import { Link } from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

const NewJobTopRight = () => {
	
	  const [editQuoteNo, setShow] = useState(false);

  return (
    <React.Fragment>
	
   					
			<div className="mb-3">							
				<label id="QuoteNo" className="mt-1">Job No. #01</label>
				<div className="row">
				{ editQuoteNo &&
				<div className="col-sm-7">
				<Input
				  name="firstname"
				  type="text"
				  className="form-control"
				  id="horizontal-firstname-Input"
				  placeholder="New Number"
				/>	
				</div>
				}
				<div className="col-sm-4 ms-0">
				<Button color="primary" outline className="btn" onClick={()=>setShow(!editQuoteNo)}>{editQuoteNo === true ? 'Update' : 'Change'}</Button>
				</div>
				</div>
			</div>	
				
			<div className="mb-3">
			<label>Priority</label>	
			<div>
			  <div className="btn-group" role="group">
			  <input type="radio" className="btn-check" name="btnradio" id="btncheck1" autoComplete="off" defaultChecked />
			  <label className="btn btn-outline-secondary mb-0" htmlFor="btncheck1">None</label>
			  <input type="radio" className="btn-check" name="btnradio" id="btncheck2" autoComplete="off" />
			  <label className="btn btn-outline-warning mb-0" htmlFor="btncheck2">Low</label>
			  <input type="radio" className="btn-check" name="btnradio" id="btncheck3" autoComplete="off" />
			  <label className="btn btn-outline-success mb-0" htmlFor="btncheck3">Medium</label>						  
			  <input type="radio" className="btn-check" name="btnradio" id="btncheck4" autoComplete="off" />
			  <label className="btn btn-outline-danger mb-0" htmlFor="btncheck4">High</label>
			</div>	
			</div>                                              
			</div>
			
			<div className="mb-3">
			<Label htmlFor="formrow-InputCity">Due Date</Label>
			<Flatpickr
			  className="form-control d-block"
			  placeholder="dd M,yyyy"
			  options={{
				altInput: true,
				altFormat: "F j, Y",
				dateFormat: "Y-m-d"
			  }}
			/>  
			</div>
			
			<div className="mb-3">
			<Label htmlFor="formrow-InputZip">Reference</Label>
			<Input
				type="text"
				name="zip"
				className="form-control"
				id="formrow-InputZip"
				placeholder=""                           
			/> 
			</div>
			
			<div className="mb-3">
			<Label htmlFor="formrow-InputZip">Quote No.</Label>
			<Input
				type="text"
				name="zip"
				className="form-control"
				id="formrow-InputZip"
				placeholder="" 
				value="QT025"
			/> 
			</div>
			
			<div className="mb-3">
			<Label htmlFor="formrow-InputState">Salesman</Label>
			<select
			name="state"
			id="formrow-InputState"
			className="form-control"                            
			>
			<option >Responsible</option>
			<option>...</option>
			</select>
			</div>	
	 
    </React.Fragment>
  )
}

export default NewJobTopRight;
