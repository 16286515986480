import React, { useState, useMemo } from "react";
import { Container, Row, Col, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown, UncontrolledDropdown } from "reactstrap"
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { Badge, Button, Card, CardBody } from "reactstrap";
import { latestTransaction } from "../../common/data/dashboard";
import TableContainer from "../../components/Common/TableContainer";
import { Link } from "react-router-dom";
import MiniWidget from "./mini-widget"

const QuotesListing = () => {

  const [modal1, setModal1] = useState(false);
  const toggleViewModal = () => setModal1(!modal1);

const columns = useMemo(
    () => [     
      {
        header: "Expense#",
        accessorKey: "orderId",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return <Link to="#" className="text-body fw-bold">{cellProps.row.original.orderId}</Link>;
        },
      },
      {
        header: "Job Title",
        accessorKey: "billingName",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Created",
        accessorKey: "orderDate",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Total",
        accessorKey: "total",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Status",
        accessorKey: "paymentStatus",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return <Badge className={"font-size-11 badge-soft-" +
            (cellProps.row.original.paymentStatus === "Accepted" ? "success" : "danger" && cellProps.row.original.paymentStatus === "Declined" ? "warning" : "danger"  && cellProps.row.original.paymentStatus === "Open" ? "primary" : "danger"  && cellProps.row.original.paymentStatus === "Cancelled" ? "warning" : "danger")}
          >
            {cellProps.row.original.paymentStatus}
          </Badge>;
        },
      },      
	  {
        header: "Reimburse to",
        accessorKey: "paymentStatus",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "",
        enableColumnFilter: false,
        enableSorting: true,
        accessorKey: "view",
        cell: cellProps => {
          return (
            <UncontrolledDropdown className="text-end float-end">
			  <DropdownToggle color="success" outline type="button" className="p-tb-0">
			  <i className="bx bx-dots-horizontal-rounded font-size-16 mt-1"></i> 
			  </DropdownToggle>
			  <DropdownMenu>
				<DropdownItem href="#"><i className="bx bx-like font-size-16 me-2"></i> Accepted</DropdownItem>
				<DropdownItem href="#"><i className="bx bx-dislike font-size-16 me-2"></i> Declined</DropdownItem>
				<DropdownItem href="#"><i className="bx bx-show-alt font-size-16 me-2"></i> View</DropdownItem>
				<DropdownItem href="#"><i className="bx bx-edit-alt font-size-16 me-2"></i> Edit</DropdownItem>
				<DropdownItem href="#"><i className="bx bx-wrench font-size-16 me-2"></i> Convert to Job</DropdownItem>
				<DropdownItem href="#"><i className="bx bx-collection font-size-16 me-2"></i> Convert to Project</DropdownItem>
			  </DropdownMenu>
			</UncontrolledDropdown>
          );
        },
      },
    ],
    []
  );
  
    const reports = [
    {
      icon: "bx bx-paper-plane",
      title: "Sent",
      value: "1,452",
      badgeValue: "+ 0.2%",
      color: "success",
      desc: "From previous period",
    },
	{
      icon: "bx bx-hourglass",
      title: "Waiting for Response",
      value: "SR 28,452",
      badgeValue: "+ 0.2%",
      color: "success",
      desc: "From previous period",
    },
    {
      icon: "bx bx-like",
      title: "Accepted",
      value: "SR 28,452",
      badgeValue: "+ 0.2%",
      color: "success",
      desc: "From previous period",
    },
    {
      icon: "bx bx-dislike",
      title: "Declined",
      value: "SR 16.2",
      badgeValue: "0%",
      color: "warning",
      desc: "From previous period",
    },
  ]

  return (
    <React.Fragment>
      <Card className="mainCard">
        <CardBody>
		
		
		<Row>
		<Col xl="12">
		  <Row>
			{/*mimi widgets */}
			<MiniWidget reports={reports} />
		  </Row>
		</Col>
	    </Row>
		
          <TableContainer
            columns={columns}
            data={latestTransaction}
            isGlobalFilter={false}
			isPagination={true}
			pagination="pagination"
            tableClass="align-middle table-nowrap mb-0"
            theadClass="table-dark"
          />
		  		  
        </CardBody>
      </Card>
	  
	  
	 
	  
	  
    </React.Fragment>
  );
};

QuotesListing.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withRouter(QuotesListing);
