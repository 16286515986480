import React from "react";
import { Card, Col, Container, Row, CardBody, CardTitle, Label, Button, Form, Input, InputGroup, FormFeedback } from "reactstrap";
import { Link } from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const CompanySettings = () => {

  return (
    <React.Fragment>
	
	<Row>
		<Col lg="6">
		<h3>Company Settings</h3>
		</Col>
		<Col lg="6" className="neg-margin">
			
		</Col>
    </Row>
	
	  <p>
		Raw denim you probably haven&apos;t heard of them jean
		shorts Austin. Nesciunt tofu stumptown aliqua, retro
		synth master cleanse. Mustache cliche tempor,
		williamsburg carles vegan helvetica. Reprehenderit
		butcher retro keffiyeh dreamcatcher synth. Cosby
		sweater eu banh mi, qui irure terry richardson ex
		squid. Aliquip placeat salvia cillum iphone. Seitan
		aliquip quis cardigan.
	  </p>
	  <p>
		Reprehenderit butcher retro keffiyeh dreamcatcher
		synth. Cosby sweater eu banh mi, qui irure terry
		richardson ex squid.
	  </p>
	  <Link to="/new-job"><Button color="success" className="btn me-2"><i className="bx bx-plus font-size-16 align-middle me-2"></i> New Job</Button></Link>
	  
	  
	 
    </React.Fragment>
  )
}

export default CompanySettings;
