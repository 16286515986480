import React, { useState } from "react";
import { Card, Col, Container, Row, CardBody, CardTitle, Label, Button, Form, Input, InputGroup, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, } from "reactstrap";
import { Link } from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import CustomerSelection from "../../Shortcut/customer-selection";
import Select from "react-select";

const NewJobTopRight = () => {
	
  const [editQuoteNo, setShow] = useState(false);
  const [modal_standard, setmodal_standard] = useState(false);
   function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }
    function tog_fullscreen() {
    setmodal_fullscreen(!modal_fullscreen);
    removeBodyCss();
  }
  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  
  const [selectedMulti, setselectedMulti] = useState(null);
  const optionGroup = [
  {
    label: "Electrical",
    options: [
      { label: "Television", value: "Mustard" },
      { label: "Air Conditioner", value: "Ketchup" },
      { label: "Water Heater", value: "Relish" }
    ]
  },
  {
    label: "Household",
    options: [
      { label: "Tent", value: "Tent" },
      { label: "Chimney", value: "Flashlight" },
      { label: "Fountain", value: "Toilet Paper" }
    ]
  }
  ];
    function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti);
  }

  return (
    <React.Fragment>
	
   					
				<Row className="mb-4">
							<h4>Job for 
							<Link 
							className="me-1 ms-1 text-body b-b-d"
							onClick={(e) => {
							e.preventDefault();
							tog_standard();
							}}>
					  Vipin Ponnamkandi
					  </Link> 
					  </h4>
						</Row>
						
					<Modal
                      isOpen={modal_standard}
                      toggle={() => {
                        tog_standard();
                      }}
                    >
                      <div>                       
                        <button
                          type="button"
                          onClick={() => {
                            setmodal_standard(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                       
					   <CustomerSelection />
					   
                      </div>
                    </Modal>
					
					<Row className="mb-3">
					<Col lg={4}>
					   <Label htmlFor="formrow-InputState">Site</Label>
					   <div className="row">
						<div className="col-sm-10 pe-1">
						<select
						name="state"
						id="formrow-InputState"
						className="form-control"                            
					    >
						<option >Select Site</option>
						<option>...</option>
					    </select>
						</div>
						<div className="col-sm-2 ps-0 ms-0">
						<Button color="warning" className="btn"><i className="bx bx-plus font-size-16 align-middle"></i></Button>
						</div>
					   </div>
					</Col>
					<Col lg={8}>
					   <Label htmlFor="formrow-InputState">Address</Label>
					   <div className="row">
						<div className="col-sm-11 pe-1">
						<Input
                            type="text"
                            name="zip"
                            className="form-control"
                            id="formrow-InputZip"
                            placeholder=""                           
                        />   
						</div>
						<div className="col-sm-1 ps-0 ms-0 me-0">
						<Button color="warning" className="btn"><i className="bx bx-map font-size-16 align-middle"></i></Button>
						</div>
					   </div>
					</Col>
					</Row>
						
						<Row className="mb-1">
						  <Label htmlFor="formrow-InputCity">Job Title</Label>
						  <Col sm={6}>
							<Input
							  name="firstname"
							  type="text"
							  className="form-control"
							  id="horizontal-firstname-Input"
							  placeholder="Job Title"
							/>								
						  </Col>
						  <Col sm={6}>
							<Input
							  name="firstname"
							  type="text"
							  className="form-control text-end ar-type"
							  id="horizontal-firstname-Input"
							  placeholder="مسمى وظيفي"
							/>								
						  </Col>
						</Row>	
						
						<Row className="mb-3">
						<Col>
						<textarea
						  id="message"
						  rows="4"
						  className="form-control rounded-bottom"
						  placeholder="Description"
						></textarea>
						</Col>
						</Row>

					<Row className="mb-3">
					<Col>
					<Label htmlFor="formrow-InputState">Equipment(s)</Label>
					<Select
						value={selectedMulti}
						isMulti={true}
						onChange={() => {
						  handleMulti();
						}}
						options={optionGroup}
						className="select2-selection"
					  />
					</Col>
					</Row>					
										
					<Row>
					<Col lg={4}>
					   <Label htmlFor="formrow-InputState">Service Contract</Label>
					   <select
						name="state"
						id="formrow-InputState"
						className="form-control"                            
					    >
						<option >Select Site</option>
						<option>...</option>
					    </select>
					</Col>
					<Col lg={8}>
					<Label htmlFor="formrow-InputState">Category</Label>
					<Input
						type="text"
						name="zip"
						className="form-control"
						id="formrow-InputZip"
						placeholder=""                           
					/>  
					</Col>
					</Row>
	 
    </React.Fragment>
  )
}

export default NewJobTopRight;
