import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Card, CardBody, CardTitle, Label, Form, Input, InputGroup, FormFeedback, 
Modal, ModalHeader, ModalBody, ModalFooter, Table, Badge, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, 
ButtonDropdown, UncontrolledDropdown, CardText, CardHeader} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Calendar from "../../Shortcut/calendar";
import Select from "react-select";

const NewJobBodyA = () => {
	
  const [selectedMulti, setselectedMulti] = useState(null);

  const optionGroup = [
  {
    label: "Picnic",
    options: [
      { label: "Mustard", value: "Mustard" },
      { label: "Ketchup", value: "Ketchup" },
      { label: "Relish", value: "Relish" }
    ]
  },
  {
    label: "Camping",
    options: [
      { label: "Tent", value: "Tent" },
      { label: "Flashlight", value: "Flashlight" },
      { label: "Toilet Paper", value: "Toilet Paper" }
    ]
  }
  ];
    function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti);
  }

  return (
    <React.Fragment>
	
				<Row className="mt-4">
				<Col lg={4}>
				  <Card outline color="primary" className="border">
					<CardHeader className="bg-transparent">
					  <h5 className="my-0 mt-2 text-primary float-start">
						<i className="mdi mdi-bullseye-arrow me-3" />
						Schedule						
					  </h5>
					  <div className="float-end mt-2">
						  <input
							className="form-check-input"
							type="checkbox"
							value=""
							id="defaultCheck1"
						  />
						  <label className="form-check-label ms-2" htmlFor="defaultCheck1">
							Recurring
						  </label>
					  </div>
					</CardHeader>
					<CardBody className="pt-2">
					  <Row className="mb-3">
					  <Col lg={6} className="me-0 pe-0">
						<Label>Start date</Label>
						<Input type="text" className="group-input-a" value="March 15, 2024" />
					  </Col>
					  <Col lg={6} className="ms-0 ps-0">
						<Label>End date</Label>
						<Input type="text" className="group-input-b" />
					  </Col>
					  </Row>
					  <Row className="mb-3">
					  <Col lg={6} className="me-0 pe-0">
						<Label>Time</Label>
						<Input type="text" className="group-input-a" placeholder="Start time" />
					  </Col>
					  <Col lg={6} className="ms-0 ps-0">
						<Label className="textWhite">End time</Label>
						<Input type="text" className="group-input-b" placeholder="End time" />
					  </Col>
					  </Row>
					  <Row>
					  <Col>
					  <input
						className="form-check-input"
						type="checkbox"
						value=""
						id="schedulelater"
					  />
					  <label className="form-check-label ms-2" htmlFor="schedulelater">
						Schedule later
					  </label>
					  </Col>
					  </Row>		
					  <div className="mt-3 d-none">					  
					  <input
						className="form-check-input"
						type="checkbox"
						value=""
						id="defaultCheck1"
						defaultChecked
					  />
					  <label className="form-check-label ms-2" htmlFor="defaultCheck1">
						Add an unscheduled visit to the calendar {/* If not checked - will not list in scheduler > Unscheduled jobs */} 
					  </label>					  
					  </div>				  
					</CardBody>
				  </Card>
				  <Card outline color="primary" className="border">
					<CardHeader className="bg-transparent">
					  <h5 className="my-0 mt-2 text-primary">
						<i className="mdi mdi-bullseye-arrow me-3" />
						Team
					  </h5>
					</CardHeader>
					<CardBody className="pt-2">
					  <div className="mb-3">
					   <Select
						value={selectedMulti}
						isMulti={true}
						onChange={() => {
						  handleMulti();
						}}
						options={optionGroup}
						className="select2-selection"
					  />
					  </div>
					  <Row className="mb-2">
					  <Col>
					  <input
						className="form-check-input"
						type="checkbox"
						value=""
						id="defaultCheck1"
					  />
					  <label className="form-check-label ms-2" htmlFor="defaultCheck1">
						Email team about assignment
					  </label>
					  </Col>
					  </Row>
					  <Row>
					  <Col>
					  <input
						className="form-check-input"
						type="checkbox"
						value=""
						id="defaultCheck1"
						defaultChecked
					  />
					  <label className="form-check-label ms-2" htmlFor="defaultCheck1">
						Send notification in Mobile App
					  </label>
					  </Col>
					  </Row>
					</CardBody>
				  </Card>
				  <Card outline color="primary" className="border">
					<CardHeader className="bg-transparent">
					  <h5 className="my-0 mt-2 text-primary">
						<i className="mdi mdi-bullseye-arrow me-3" />
						Forms
					  </h5>
					</CardHeader>
					<CardBody className="pt-2">
					   <div className="mb-3">
					   <Select
						value={selectedMulti}
						isMulti={true}
						onChange={() => {
						  handleMulti();
						}}
						options={optionGroup}
						className="select2-selection"
					  />
					  </div>
					</CardBody>
				  </Card>
				</Col>		
				<Col lg={8}>
				<div className="ps-4">
				  <Calendar />	
				</div>
				</Col>									
				</Row>
				
				<Row>
				<Col lg={4}>
				  <Card outline color="primary" className="border">
				  <CardHeader className="bg-transparent">
					  <h5 className="my-0 mt-2 text-primary">
						<i className="mdi mdi-bullseye-arrow me-3" />
						Invoicing
					  </h5>
					</CardHeader>
					<CardBody className="pt-2">
					<Row>
				    <Col lg={12}>
					  <input
						className="form-check-input"
						type="checkbox"
						value=""
						id="defaultCheck1"
						defaultChecked
					  />
					  <label className="form-check-label ms-2" htmlFor="defaultCheck1">
						Remind me to invoice when job closed
					  </label>
				  </Col>
				  </Row>
				  </CardBody>
				  </Card>
				</Col>
				</Row>
	 
    </React.Fragment>
  )
}

export default NewJobBodyA;
